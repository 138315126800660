import React, { Component } from "react";
import Logout from "views/Logout";

import logo from "assets/img/resbeelogo.png";
import bee from "assets/img/bee.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "Requests",
    };
  }

  switchView(id) {
    // Highlight selected option
    this.setState({ selected: id });
    // Change the view
    this.props.switchView(id);
  }

  dashLinks(route) {
    let selected = (route.id === this.state.selected) ? "selected" : "";
    return (<a key={route.name}
              href={`#${route.id}`}
              onClick={() => this.switchView(route.id)}
              className={`list-group-item list-group-item-action bg-light ${selected}`}>
              <i className={`fas fa-${route.icon}`}></i>
              {route.name}
              {(route.id === "overview" && this.props.bookingRequests > 0)
                ? <img alt="bee" src={bee} className="new-bee" /> : ""}
              {(route.id === "bookings" && this.props.newAutoconfirmedBooking)
                ? <img alt="bee" src={bee} className="new-bee new-bee-chime" /> : ""}
            </a>);
  }

  render() {
    return (
      <div className="bg-light border-right" id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom">

          <img alt="logo" src={logo} id="resbee-logo" />
        </div>
        <div className="list-group list-group-flush">
          {this.props.routes.map(route => this.dashLinks(route))}
        </div>
        <div id="sidebar-footer">
          <Logout />
        </div>
      </div>
    );
  }
}

export default Sidebar;
