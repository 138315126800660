// live incoming request

import React, { Component } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { parse, stringify } from "flatted/cjs";
import { getPhoneNationality } from 'resources/countries';
import axios from "axios";
import moment from "moment";
import bee from "assets/img/bee.png";
import DatePicker from 'react-datepicker';
import Bookings from "views/Bookings";
import BeatLoader from "react-spinners/BeatLoader";
import ReactiveTable from "components/ReactiveTable";
import MobileTable from "components/MobileTable";
import OpeningHours from 'components/OpeningHours';
import LanguageContext from "LanguageContext";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const dateFormat = "YYYY-MM-DD";

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTimes: [],
      showModal: false,
      triggerReload: false,
      selectedBookingRequest: false,
      alternative_times: null,
      bookingsTableDate: moment().format(dateFormat),
      isLoading: true,
      requests: [],
      waitingList: [],
      viewportWidth: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accessString = localStorage.getItem('JWT');
  };

  async getBookingRequests() {
    const response = await axios.get("getClientRequests", {
      headers: { Authorization: `JWT ${this.accessString}` },
    });
    // Prevent timezone issues by calculating relative arriving times in front end
    let bookings = response.data.map(booking => {
      booking.arriving_unix = moment(`${booking.date} ${booking.time}`, "YYYY-MM-DD HH:mm").unix();
      return booking;
    });
    this.setState({
      isLoading: false,
      requests: bookings,
    });
  }

  async actionBookingRequest(action, requestId, counterOffers) {
    this.setState({ isLoading: true });
    const response = await axios.post(
      `${action}BookingRequest`,
      { requestId: requestId, counterOffers: counterOffers },
      { headers: { Authorization: `JWT ${this.accessString}` }}
      );

    if(response.status === 200) {
      // remove requesting._id row from table
      let updatedRequestsList = this.state.requests.filter(request => request._id !== requestId);
      this.props.minusBookingRequest(updatedRequestsList.length);
      this.setState({
        selectedBookingRequest: false,
        showModal: false,
        triggerReload: ! this.state.triggerReload,
        isLoading: false,
        requests: updatedRequestsList,
      });
      this.getWaitingListFor(this.state.bookingsTableDate);
    } else {
      alert(JSON.stringify(response));
      this.setState({ isLoading: false });
    }
  }

  updateWindowDimensions() {
    this.setState({ viewportWidth: window.innerWidth});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getBookingRequests();
    this.getWaitingListFor(moment().format(dateFormat));
  }

  componentDidUpdate(prevProps) {
    // Prevent "too many nested updates" react error hell
    if(prevProps.bookingRequests !== this.props.bookingRequests) {
      this.getBookingRequests();
    }
  }

  selectBookingRequest(id, requestingDate) {
    this.setState({
      selectedBookingRequest: id,
      // Filter confirmed bookings table to selected booking request date
      bookingsTableDate: requestingDate,
      showModal: true,
    });
    this.getWaitingListFor(requestingDate);
    this.getSittingsFor(requestingDate);
  }

  showBookingsOnDate(date) {
    this.setState({ bookingsTableDate: date });
    this.getWaitingListFor(date);
  }

  renderRequestRow(request) {
    return (
      <tr
        key={request._id}
        className={(request._id === this.state.selectedBookingRequest) ? "selected" : ""}
        onClick={() => this.selectBookingRequest(request._id, request.date)}
      >
        <td>
          <b>{moment(request.date, dateFormat).format("MMM Do")} {request.time}</b>
          <br />
          <i>{moment(request.arriving_unix * 1000).calendar()}</i>
        </td>
        <td className="rt-emphasise-cell">{request.group_size}</td>
        <td>{request.first_name} {request.last_name}</td>
        <td className="cell-nationality">
          <PhoneInput
            disabled={true}
            value={request.phone_number}
            onChange={() => {}}
          />
          <div className="text-nationality">{getPhoneNationality(request.phone_number)}</div>
        </td>
        <td>{request.additional_info}</td>
        <td className="rt-emphasise-cell">{request.bookings}</td>
      </tr>
    );
  }
 
  renderRequests() {
    if(this.state.isLoading) return (
      <div className="loading"><BeatLoader size={50} /></div>
    );
    if( ! this.state.requests.length) return (
      <div className="page-notice">
        <img alt="bee" src={bee} className="bee" />
      </div>
    );
    return (
      <LanguageContext.Consumer>{language => (
        <Table className="requests-table">
          <thead>
            <tr>
              <th>{language.columns.for}</th>
              <th>{language.columns.covers}</th>
              <th>{language.columns.name}</th>
              <th>{language.columns.nationality}</th>
              <th>{language.columns.additional_info}</th>
              <th>{language.columns.number_of_bookings}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.requests.map(request => this.renderRequestRow(request))}
          </tbody>
        </Table>
      )}</LanguageContext.Consumer>
    );
  }

  selectedCalendarDate(language) {
    return moment(this.state.bookingsTableDate, dateFormat).calendar(null, language.dates);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  showRejectedBooking() {

  }

  async getWaitingListFor(date) {
    const waitingList = await axios.get(`getWaitingList?date=${date}`,
      { headers: { Authorization: `JWT ${this.accessString}` }},
    );
    this.setState({waitingList: waitingList.data});
  }

  showUnconfirmed(list) {
    return (
      <LanguageContext.Consumer>{language => (
        <>
        <h3>{language.common.unconfirmed} {this.selectedCalendarDate(language)}</h3>
        {this.state.viewportWidth < 1000 ?
          <MobileTable
            rowClick={(id) => this.showRejectedBooking(id)}
            columns={this.getColumns(language)}
            data={list}
          />
          :
          <ReactiveTable
            rowClick={(id) => this.showRejectedBooking(id)}
            columns={this.getColumns(language)}
            data={list}
          />
        }
        </>
      )}</LanguageContext.Consumer>
    );
  }

  async getSittingsFor(date) {
    const settings = await axios.get(`getClientSettings`,
      { headers: { Authorization: `JWT ${this.accessString}` }},
    );
    if(settings.data) {
      const openingHours = parse(settings.data.maitred.opening_hours);
      let weekday = moment(date, "YYYY-MM-DD").locale("en").format("dddd").toLowerCase();
      let selectedSittings = openingHours.normal[weekday];
      this.setState({alternative_times: selectedSittings});
    }
  }

  handleTimeCheck(e) {
    const item = e.target.name;
    let updatedCheckedTimes = this.state.checkedTimes;
    if(e.target.checked) {
      updatedCheckedTimes.push(e.target.value);
    } else {
      let remove = updatedCheckedTimes.indexOf(e.target.value);
      updatedCheckedTimes.splice(remove, 1);
    }
    this.setState({ checkedTimes: updatedCheckedTimes });
  }

  offerAlternatives(e) {
    if(e) e.preventDefault();
    this.actionBookingRequest(
      "counter",
      this.state.selectedBookingRequest,
      stringify(this.state.checkedTimes)
    );
  }

  alternativeTimesForm(day) {
    if( ! day.open) return <i>Closed on this day</i>;
    return (
      <LanguageContext.Consumer>{language => (
        <Form onSubmit={() => this.offerAlternatives()}>
          <OpeningHours
            openingHours={this.state.alternative_times}
            type="checkbox"
            handleChange={(e) => this.handleTimeCheck(e)}
          />
          <Button variant="primary" type="submit" className="offer-times" size="lg">
            {language.common.offer_times}
          </Button>
        </Form>
      )}</LanguageContext.Consumer>
    );
  }

  modalHeader() {
    const selectedRequest = this.state.requests.filter(request => {
      return request._id === this.state.selectedBookingRequest;
    }).pop();
    if(typeof selectedRequest === "undefined") return;
    return (
      <div className="header-text">
        <i class="fas fa-user-friends"></i>{selectedRequest.group_size}
        <i class="fas fa-clock"></i>{selectedRequest.time}
        <i class="fas fa-calendar-alt"></i>{moment(selectedRequest.date, dateFormat).format("MMM Do")}
      </div>
    );
  }

  getColumns(language) {
    return [
      {
        Header: language.columns.type,
        accessor: 'counter_offers',
        type: "switch"
      },
      {
        Header: language.columns.requested,
        accessor: 'arriving_unix',
        type: 'calendar',
      },
      {
        Header: language.columns.covers,
        accessor: 'group_size',
      },
      {
        Header: language.columns.date,
        accessor: 'date',
        type: 'date'
      },
      {
        Header: language.columns.time,
        accessor: 'time',
      },
      {
        Header: language.columns.name,
        accessor: 'first_name',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.surname,
        accessor: 'last_name',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.mobile,
        type: "phone_number",
        accessor: 'phone_number',
        filter: 'StringColumnFilter',
      }
    ];
  }

  render() {
    return(
      <LanguageContext.Consumer>{language => (
      <div className="overview-page">
        <Modal
          className="options-popup"
          show={this.state.showModal}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            {this.modalHeader()}
          </Modal.Header>
          <Modal.Body>
          <Button
            onClick={() => this.actionBookingRequest("accept", this.state.selectedBookingRequest)}
            variant="success" size="lg" >
            {language.common.accept}
          </Button>
          <h3>{language.common.offer_alternatives}</h3>
          {(this.state.alternative_times)
            ? this.alternativeTimesForm(this.state.alternative_times)
            : <BeatLoader />}
          <h3>{language.common.fully_booked}</h3>
          <Button
            onClick={() => this.actionBookingRequest("reject", this.state.selectedBookingRequest)}
            variant="danger" size="lg" >
            {language.common.add_waiting}
          </Button>
          </Modal.Body>
        </Modal>
        {this.renderRequests()}
        <table className="date-navigator">
        <tbody>
        <tr>
        <td><Button
          size="lg"
          onClick={date => this.showBookingsOnDate(moment(this.state.bookingsTableDate, dateFormat).add(-1, 'day').format(dateFormat))}>
          <i className="fas fa-arrow-circle-left"></i></Button></td>
        <td><DatePicker
          placeholderText={this.selectedCalendarDate(language)}
          onChange={date => this.showBookingsOnDate(moment(date).format(dateFormat))}
        /></td>
        <td><Button
          size="lg"
          onClick={date => this.showBookingsOnDate(moment(this.state.bookingsTableDate, dateFormat).add(1, 'day').format(dateFormat))}>
          <i className="fas fa-arrow-circle-right"></i></Button></td>
        </tr></tbody></table>
        <h3>{language.common.confirmed}</h3>
        <Bookings
          language={this.props.language}
          triggerReload={this.state.triggerReload}
          date={this.state.bookingsTableDate}
        />
        {this.state.waitingList.length ? this.showUnconfirmed(this.state.waitingList) : ""}
      </div>
      )}</LanguageContext.Consumer>
    );
  }
}

export default Requests;
