// [public facing] maitred public booking form wrapper

import React, { Component } from "react";
import { Container, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { parse } from "flatted/cjs";
import axios from "axios";
import moment from "moment";
import MobilePhone from "components/MobilePhone";
import DotLoader from "react-spinners/DotLoader";
import MaitredForm from "views/MaitredForm";
import BookingRequest from "views/BookingRequest";
import Select from "react-select";
import languages from "languages/maitred";
import "assets/css/Maitred.css";

import io from 'socket.io-client';
const socket = io.connect(process.env.REACT_APP_SOCKET, {
   transports: ['websocket'],
   secure: true
});

const dateFormat = "YYYY-MM-DD";

const origins = {
  insta: "instagram",
  web: "web",
  fb: "facebook",
}

class Maitred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "en",
      is404: false,
      setup: null,
      hash: (this.props.match.params.hash) ? (this.props.match.params.hash) : null,
      sittings: null,
      isLoading: true,
      bookingRequest: null,
      requestSubmitted: false,
      requestSubmittedHash: null,
    };
  };

  async componentDidMount() {
    const uri = `getMaitredSetup?uid=${this.props.match.params.uid}`;
    const countryCode = await axios.get('https://ipapi.co/json/');
    const response = await axios.get(uri);
    if(response.data) {
      this.setState({
        setup: {
          country_code: countryCode.data.country_code,
          establishment_name: response.data.establishment_name,
          guests: [1,2,3,4,5,6],
          opening_hours: response.data.opening_hours,
          styles: response.data.form_styles,
        }
      });
    }
    else {
      this.setState({ is404: true });
    }
    this.languageGeoswitch();
    this.setState({ isLoading: false });
  }

  getSittingsForDate(date) {
    const weekDay = moment(date, dateFormat).locale("en").format("dddd").toLowerCase();
    const opening_hours = parse(this.state.setup.opening_hours);
    let selected_sittings = opening_hours.normal[weekDay];
    this.setState({ sittings: selected_sittings });
  }

  async findCustomerBooking() {
    this.setState({ isLoading: true });
    const phone_number = document.getElementById('formatted_phone_number').value;
    const uri = "getClientCustomerBooking";
    const query = {
      uid: this.props.match.params.uid,
      hash: this.props.match.params.hash,
      phone_number: phone_number
    }
    try {
      const response = await axios.get(uri, {params: query});
      this.setState({ isLoading: false, bookingRequest: response.data });
    } catch(error) {
      alert("Booking not found - please check your mobile number");
      this.setState({ isLoading: false });
    }
  }

  async requestAlternativeTime(offer) {
    let data = {
      uid: this.props.match.params.uid,
      requestId: this.state.bookingRequest._id,
      created: this.state.bookingRequest.created,
      date: this.state.bookingRequest.date,
      group_size: this.state.bookingRequest.group_size,
      time: offer,
    };
    try {
      const response = await axios.post(
        "requestBooking",
        data
      );
      // Show success
      if(response.status == 200) this.requestSubmitted(response.data);
    } catch (error) {
      console.error(JSON.stringify(error.response));
    }
  }

  requestSubmitted(bookingRequest) {
    if(bookingRequest.accepted) {
      socket.emit('autoConfirm', this.props.match.params.uid);
    } else {
      socket.emit('bookingRequest', this.props.match.params.uid);
    }
    this.setState({
      requestSubmitted: true,
      hash: bookingRequest.hash,
      bookingRequest: bookingRequest,
    });
    this.props.history.push(bookingRequest.hash);
  }

  getOrigin = () => {
    return origins[this.props.match.params.origin]
      ? origins[this.props.match.params.origin]
      : "web";
  }

  statusText = () => {
    if(this.state.bookingRequest.accepted) return "Confirmed";
    if(this.state.bookingRequest.counter_offers)
      return `${this.state.setup.establishment_name} has offered ${(parse(this.state.bookingRequest.counter_offers).length > 1) ? "alternative times" : "an alternative time"}`;
    if(this.state.bookingRequest.waiting_list) return "On Waiting List";
    return "Pending Confirmation"
  }

  renderAlternativeTimeSelection = () => {
    return parse(this.state.bookingRequest.counter_offers).map(offer => {
      return (
        <div className="alternative-time-offer">
          <Button size="lg" onClick={() => this.requestAlternativeTime(offer)}>
            {moment(this.state.bookingRequest.date, dateFormat).format("MMM Do")} @ {offer}
          </Button>
        </div>
      );
    });
  }

  renderBookingRequest() {
    return (
      <div className="booking-request-view sparse-maitred">
        <h1>{this.state.setup.establishment_name}</h1>
        {this.state.bookingRequest.first_name} {this.state.bookingRequest.last_name}<br />
        {this.state.bookingRequest.group_size} Guest{(this.state.bookingRequest.group_size > 1) ? "s" : ""}<br />
        {moment(this.state.bookingRequest.date, dateFormat).format("MMM Do")}
        {` @ `}
        {this.state.bookingRequest.time}
        <br />
        <Button
          className="sparse-button"
          disabled
          size="lg"
          variant={(this.state.bookingRequest.accepted || this.state.bookingRequest.counter_offers) ? "success" : "danger"}>
          {this.statusText()}
        </Button>
        {this.state.bookingRequest.counter_offers ? this.renderAlternativeTimeSelection() : ""}
        <br />
        <br />
        {( ! this.state.bookingRequest.accepted)
          ? <i>We will notify you by SMS when your booking request is confirmed.</i>
          : <i>We look forward to welcoming you at {this.state.setup.establishment_name}</i> }
      </div>
    );
  }

  renderBookingRequestPseudoLogin() {
    return (
      <div className="maitred-form">
        <h3>Retrieve Booking</h3>
        <MobilePhone setup={this.state.setup} />
        <Button
          variant="primary"
          size="lg"
          onClick={() => this.findCustomerBooking()}>
          Find
        </Button>
      </div>
    );
  }

  changeLanguage(languageOption) {
    this.setState({ language: languageOption.value });
  };

  languageGeoswitch() {
    axios.get('https://ipapi.co/json/').then((response) => {
      let language = response.data.languages.substring(0,2);
      let match = languages.options.filter(option => {
        return option.value === language;
      });
      if(match.length) {
        this.setState({ language: language });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  render() {
    // If loading
    if(this.state.isLoading) return (
      <Container>
        <div className="loading-maitred"><DotLoader size={200} /></div>
      </Container>
    );

    // If UID not found
    if(this.state.is404) return (<Redirect to={"/home"} />);

    // If booking request in state, show booking request jazz
    if(this.state.bookingRequest) return (
      <BookingRequest setup={this.state.setup} content={this.renderBookingRequest()} />
    );

    // If hash supplied
    if(this.state.hash) return (
      <BookingRequest setup={this.state.setup} content={this.renderBookingRequestPseudoLogin()} />
    );

    // Default to show form
    return (
      <Container>
        <div className="ext-language-switch">
          <Select
            value={this.state.language}
            onChange={(selectedLanguage) => this.changeLanguage(selectedLanguage)}
            options={languages.options}
            placeholder="🌎 Language"
          />
        </div>
        <MaitredForm
          language={languages[this.state.language]}
          requestSubmitted={(bookingRequest) => this.requestSubmitted(bookingRequest)}
          setup={this.state.setup}
          uid={this.props.match.params.uid}
          origin={this.getOrigin()}
          getSittingsForDate={(date) => this.getSittingsForDate(date)}
          selected_opening_hours={this.state.sittings}
        />
      </Container>
    );
  }
}

export default Maitred;
