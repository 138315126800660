import React from 'react';
import { Container } from "react-bootstrap";
import { parse } from "flatted/cjs";
import WebFont from 'webfontloader';
import { createGlobalStyle } from 'styled-components';

export default function BookingRequest(props) {
  /* Establishment Specific Styles */
  let styles_master = parse(props.setup.styles);
  let styles = {...styles_master.general, ...styles_master.buttons, ...styles_master.confirmation };

  const getStyle = style => {
    return (styles[style]) ? styles[style].value : "";
  }

  WebFont.load({
    google: {
      families: [styles.font_import.value, styles.title_font_import.value]
    }
  });

  const GlobalStyles = createGlobalStyle`

    body {
      background-color: ${getStyle("background_color")};
      background-image: url(${getStyle("background_image")});
      font-family: ${getStyle("font_import")}, sans-serif;
      font-size: ${getStyle("font_size")};
      color: ${getStyle("font_color")};
      text-shadow: ${getStyle("text_shadow")};
    }

    h2 {
      font-family: ${getStyle("title_font_import")}, sans-serif;
      font-size: ${getStyle("title_font_size")};
      color: ${getStyle("title_font_color")};
      text-shadow: ${getStyle("title_text_shadow")};
    }
  `;

  return (
    <Container className="retrieve-booking-form">
      <GlobalStyles />
      {getStyle("establishment_logo") ? <img className="establishment-logo" src={getStyle("establishment_logo")} /> : ""}
      {props.content}
    </Container>
  );
}
