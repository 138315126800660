import React from 'react';
import { getPhoneNationality } from 'resources/countries';
import ReactiveTable from 'components/ReactiveTable';
import PhoneInput from 'react-phone-number-input';
import BeatLoader from 'react-spinners/BeatLoader';
import LanguageContext from "LanguageContext";
import web from "assets/img/logo.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import moment from 'moment';
import axios from 'axios';

const dateFormat = "YYYY-MM-DD";

const renderOrigin = name => {
  if ( ! name) return "";
  if(name === "web")
    return <img className="origin-logo" src={web} />;
  if(name === "facebook")
    return <img className="origin-logo" src={facebook} />;
  if(name === "instagram")
    return <img className="origin-logo" src={instagram} />;
}

const Customer = ({ id }) => {
    const [customer, setCustomer] = React.useState(false);
    const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', () => setViewportWidth(window.innerWidth));
        const accessString = localStorage.getItem('JWT');
        axios.get("getClientCustomer", {
            headers: { Authorization: `JWT ${accessString}` },
            params: { id: id }
        }).then(response => {
            setCustomer(response.data);
        });
        return () => {
            window.removeEventListener('resize', () => setViewportWidth(window.innerWidth));
        };
    }, []);

    const getColumns = language => {
        return [
            {
              Header: language.columns.arriving,
              accessor: 'arriving_unix',
              type: 'calendar',
            },
            {
              Header: language.columns.covers,
              accessor: 'group_size',
              type: 'emphasise'
            },
            {
              Header: language.columns.date,
              accessor: 'date',
              type: 'date'
            },
            {
              Header: language.columns.time,
              accessor: 'time',
            },
            {
              Header: language.columns.origin,
              accessor: 'origin',
              type: 'origin',
            },
        ];
    }

    /**
     * Because Instagram handles are taken at point of booking they are stored in the booking object
     * not the customer object. scanForInstaHandle is responsible for obtaining a customer's instagram 
     * handle from their bookings
     */
    const scanForInstaHandle = bookings => {
        let instagramHandle = null;
        bookings.map(booking => {
            if ( ! instagramHandle && booking.instagram_handle) instagramHandle = booking.instagram_handle;
        });
        return instagramHandle ? instagramHandle : "—————————";
    }

    const renderCustomer = () => {
        console.log(customer)
        return (
            <LanguageContext.Consumer>{language => (<>
                <div className="customer-name">
                    {customer.first_name} {customer.last_name}
                </div>
                <br />
                <div className="text-nationality">{getPhoneNationality(customer.phone_number)}</div>
                {viewportWidth < 768 ?
                <>
                    <div className="customer-section customer-table">
                        <div className="customer-subsection-mob">
                            <PhoneInput
                                disabled={true}
                                value={customer.phone_number}
                                onChange={() => {}}
                            />
                        </div>
                        <div>
                            <img className="instagram-logo" src={instagram} alt="instagram" />
                            <div className="instagram-handle">{scanForInstaHandle(customer.bookings)}</div>
                        </div>
                    </div>
                    <table className="customer-visits">
                        <tr>
                            <td><i class="fas fa-user-friends"></i></td>
                            <td><i class="fas fa-clock"></i></td>
                            <td><i class="fas fa-calendar-alt"></i></td>
                            <td><i class="fas fa-arrow-down"></i></td>
                        </tr>
                        {customer.bookings.map(booking => {
                            return (
                                <tr className="mobile-booking-row">
                                    <td>
                                        {booking.group_size}
                                    </td>
                                    <td>
                                        {booking.time}
                                    </td>
                                    <td>
                                        {moment(booking.date, dateFormat).format("MMM Do")}
                                    </td>
                                    <td>
                                        {renderOrigin(booking.origin)}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </>
                :
                <>
                    <div className="customer-section customer-table">
                        <div className="customer-subsection">
                            <PhoneInput
                                disabled={true}
                                value={customer.phone_number}
                                onChange={() => {}}
                            />
                        </div>
                        <div className="customer-subsection">
                            <img className="instagram-logo" src={instagram} alt="instagram" />
                            <div className="instagram-handle">{scanForInstaHandle(customer.bookings)}</div>
                        </div>
                    </div>
                    <div className="customer-section">
                        <ReactiveTable
                            rowClick={() => {}}
                            columns={getColumns(language)}
                            data={customer.bookings}
                        />
                    </div>
                </>
                }
            </>)}</LanguageContext.Consumer>
        )
    }

    return customer ? renderCustomer() : <div className="loading"><BeatLoader size={40} /></div>;
}

export default Customer;