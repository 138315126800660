// calendar bookings (ie. accepted requests)

import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import ReactiveTable from "components/ReactiveTable";
import MobileTable from "components/MobileTable";
import LanguageContext from "LanguageContext";

class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bookings: [],
      viewportWidth: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const date = this.props.date ? `?date=${this.props.date}` : "";
    const accessString = localStorage.getItem('JWT');
    const response = await axios.get(`getClientBookings${date}`,
      { headers: { Authorization: `JWT ${accessString}` }},
    );
    // Prevent timezone issues by calculating relative arriving times in front end
    let bookings = response.data.map(booking => {
      booking.arriving_unix = moment(`${booking.date} ${booking.time}`, "YYYY-MM-DD HH:mm").unix();
      return booking;
    });
    this.setState({
      isLoading: false,
      bookings: bookings,
    });
  }

  updateWindowDimensions() {
    this.setState({ viewportWidth: window.innerWidth});
  }

  componentDidUpdate(prevProps, prevState) {
    // Prevent "too many nested updates" react error hell
    if(prevProps.date !== this.props.date || prevProps.triggerReload !== this.props.triggerReload) {
      this.setState({ isLoading: true });
      this.componentDidMount()
    }
  }

  showBooking(id) {
    console.log(`Modal popup for booking ${id}`)
  }

  getColumns(language) {
    return [
      {
        Header: language.columns.arriving,
        accessor: 'arriving_unix',
        type: 'calendar',
      },
      {
        Header: language.columns.covers,
        accessor: 'group_size',
        type: 'emphasise'
      },
      {
        Header: language.columns.date,
        accessor: 'date',
        type: 'date'
      },
      {
        Header: language.columns.time,
        accessor: 'time',
      },
      {
        Header: language.columns.name,
        accessor: 'first_name_last_name',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.mobile,
        type: "phone_number",
        accessor: 'phone_number',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.additional_info,
        accessor: 'additional_info',
      },
      {
        Header: language.columns.origin,
        accessor: 'origin',
        type: 'origin',
      },
    ];
  }

  render() {
    if(this.state.isLoading) return <div className="loading"><BeatLoader size={50} /></div>;
    return (
      <LanguageContext.Consumer>{language => (
        <>
        {this.state.viewportWidth < 1000 ?
          <MobileTable
            rowClick={(id) => this.showBooking(id)}
            columns={this.getColumns(language)}
            data={this.state.bookings}
          />
          :
          <ReactiveTable
            rowClick={(id) => this.showBooking(id)}
            columns={this.getColumns(language)}
            data={this.state.bookings}
          />
        }
        </>
      )}</LanguageContext.Consumer>
    );
  }
}

export default Bookings;
