// New reservation

import React, { Component } from "react";
import MaitredForm from "views/MaitredForm";
import BeatLoader from "react-spinners/BeatLoader";
import LanguageContext from "LanguageContext";
import { parse, stringify } from "flatted/cjs";
import axios from "axios";
import moment from "moment";
import languages from "languages/maitred";
import tick from "assets/img/tick.gif";

const dateFormat = "YYYY-MM-DD";

export default class NewBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      isLoading: true,
      maitred: {},
      sittings: null,
      maitredSettings: {},
      submitted: false,
    };
    this.accessString = localStorage.getItem('JWT');
  };

  async componentDidMount() {
    const response = await axios.get("getClientSettings", {
      headers: { Authorization: `JWT ${this.accessString}` },
    });
    this.setState({
      uid: response.data.profile.uid,
      isLoading: false,
      maitred: {
        uid: response.data.profile.uid,
        establishment_name: response.data.maitred.establishment_name,
        guests: [1,2,3,4,5,6],
        opening_hours: response.data.maitred.opening_hours,
        styles: response.data.maitred.form_styles,
      },
      maitredSettings: parse(response.data.maitred.form_styles),
    });
  }

  getSittingsForDate(date) {
    const opening_hours = parse(this.state.maitred.opening_hours);
    let selected_sittings = opening_hours.normal[moment(date, dateFormat).locale("en").format("dddd").toLowerCase()];
    this.setState({ sittings: selected_sittings });
  }

  requestSubmitted() {
    this.setState({ submitted: true });
    this.props.newAutoconfirmedBooking();
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 2000);
  }

  render() {
    if(this.state.isLoading) return (
      <div className="loading"><BeatLoader size={50} /></div>
    );
    if(this.state.submitted) return (
      <div className="center-page-wrapper">
        <div className="center-page">
          <img className="success-tick" src={tick} />
        </div>
      </div>
    );
    return (
      <LanguageContext.Consumer>{language => (
        <div className="new-booking-container">
          <MaitredForm
            internal={true}
            language={languages[language.is]}
            requestSubmitted={(bookingRequest) => this.requestSubmitted(bookingRequest)}
            setup={this.state.maitred}
            uid={this.state.uid}
            origin="web"
            getSittingsForDate={(date) => this.getSittingsForDate(date)}
            selected_opening_hours={this.state.sittings}
          />
        </div>
      )}</LanguageContext.Consumer>
    );
  }
}
