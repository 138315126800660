import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import { Form, Col, Button } from "react-bootstrap";
import { css } from "@emotion/core";
import ClockLoader from "react-spinners/ClockLoader";
import axios from "axios";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email_address: '',
      password: '',
      attempts: 0,
      overall_attempts: 0,
      loggedIn: false,
      showError: false,
      resettingPassword: false
    };
  }

  componentDidUpdate(prevProps) {
    // prevent "too many nested updates" react error hell
    if(prevProps.email_address !== this.props.email_address) {
      if(this.props.email_address && this.props.password) {
        this.handleLogin(this.props.email_address, this.props.password);
      }
    }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleLogin = async (email_address, password) => {
    try {
      const response = await axios.post('loginClient', {
        email_address,
        password,
      });

      // Store JWT for use in API calls
      localStorage.setItem('JWT', response.data.token);
      this.setState({
        loggedIn: true,
        showError: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        showError: true,
      });
    }
  }

  loginUser = (e) => {
    if(e) e.preventDefault();
    const { email_address, password } = this.state;

    if(this.state.attempts > 2) {
      setTimeout(function(){
        this.setState({
          showError: false,
          loggedIn: false,
          attempts: 2
        });
      }.bind(this), (1000 * ((this.state.overall_attempts) * 2)));
    }

    if (email_address === '' || password === '') {
      this.setState({
        showError: true,
        loggedIn: false,
      });
    } else {
      this.setState({
        attempts:(this.state.attempts + 1),
        overall_attempts:(this.state.overall_attempts + 1)
      });

      this.handleLogin(email_address, password);
    }
  };

  resetPassword = () => {
    this.setState({resettingPassword:true});
  }

  resettingPassword = () => {
    return (
      <Form onSubmit={this.resetPasswordForUser}>
        <Form.Group>
          <Form.Label>{this.props.language.enter_registered}</Form.Label>
          <Form.Control type="email" onChange={this.handleChange('email_address')} />
        </Form.Group>
        <Button variant="danger" type="submit" size="lg">
          {this.props.language.email_password}
        </Button>
      </Form>
    );
  }

  resetPasswordForUser = async (e) => {
    e.preventDefault();

    const { email_address } = this.state;

    try {
      const response = await axios.post('resetClientPassword', {
        email_address: email_address
      });
      alert("Password reset, please check your email");
      this.setState({
        attempts: 0,
        overall_attempts: 0,
        loggedIn: false,
        showError: false,
        resettingPassword: false
      });
    } catch (error) {
      alert("Something went wrong, you may need to contact us to reset your password");
      console.log(error);
    }
  }

  render() {
    if (this.state.attempts > 3) return <div className="loading"><ClockLoader size={100} /></div>;
    if (this.state.resettingPassword) return this.resettingPassword();
    if ( ! this.state.loggedIn) {
      return (
        <Form className={(this.state.showError) ? "error" : ""} onSubmit={this.loginUser}>
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicEmail">
            <Form.Label>{this.props.language.email}</Form.Label>
            <Form.Control onChange={this.handleChange('email_address')} type="email" placeholder={this.props.language.email_placeholder} />
          </Form.Group>

          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Label>{this.props.language.password}</Form.Label>
            <Form.Control onChange={this.handleChange('password')} type="password" placeholder={this.props.language.password} />
          </Form.Group>
        </Form.Row>
        {this.state.showError && (
          <div className="error-message">
            {this.props.language.login_incorrect}
          </div>
        )}

        <Form.Row>
          <Button variant="primary" type="submit" size="lg">
            {this.props.language.login_button}
          </Button>
          {(this.state.showError && this.state.overall_attempts > 1) && (
            <Button onClick={() => this.resetPassword()} variant="danger" size="lg">
              {this.props.language.reset_password}
            </Button>
          )}
        </Form.Row>
        </Form>
      );
    } else { // logged in
      return <Redirect to={"/dashboard"} />;
    }
  };
};

export default Login;
