import React, { Component } from "react";
import { Navbar, Dropdown, DropdownButton } from "react-bootstrap";
import Logout from "views/Logout";

import logo from "assets/img/resbeelogo.png";
import bee from "assets/img/bee.png";

class Header extends Component {

  dashLinks(route) {
    return (<Dropdown.Item
              as="button"
              key={route.id}
              onClick={() => this.props.switchView(route.id)}
            >
                <i className={`fas fa-${route.icon}`}></i>
                {route.name}
            </Dropdown.Item>);
  }

  render() {
    return (
      <Navbar id="header" className="border-bottom bg-light d-flex">
        <Navbar.Brand className="mr-auto">
          <img alt="logo" src={logo} id="resbee-logo-small" />
        </Navbar.Brand>
        <div id="header-arrow-wrapper">
        {(this.props.bookingRequests > 0) ? <img alt="bee" src={bee} onClick={() => this.props.switchView('overview')} className="new-bee" /> : ""}
        </div>
        <DropdownButton alignRight id="dropdown-item-button" title="☰ Menu" className="float-right">
          {this.props.routes.map(route => this.dashLinks(route))}
          <div id="dropdown-footer"><Logout /></div>
        </DropdownButton>
      </Navbar>
    );
  }
}

export default Header;
