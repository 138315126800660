const dashboardRoutes = [
  {
    id: "new",
    name: "NewBooking",
    icon: "plus",
  },
  {
    id: "overview",
    name: "Overview",
    icon: "arrow-circle-right",
  },
  {
    id: "spacetime",
    name: "Spacetime",
    icon: "th",
  },
  {
    id: "bookings",
    name: "Bookings",
    icon: "book-open",
  },
  {
    id: "customers",
    name: "Customers",
    icon: "users",
  },
  {
    id: "analytics",
    name: "Analytics",
    icon: "chart-line",
  },
  {
    id: "settings",
    name: "Settings",
    icon: "cog",
  },
  {
    id: "account",
    name: "Account",
    icon: "user-circle",
  }
];

export default dashboardRoutes;
