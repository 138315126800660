import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDrag } from 'react-dnd';
import web from "assets/img/logo.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";

export default function GridBooking(props) {
  const [{isDragging}, drag] = useDrag({
    item: { type: "booking" },
    begin: () => props.showDropZones(),
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const style = {
    opacity: isDragging ? 0.5 : 1,
    minWidth: props.width,
    width: props.width,
    height: `${3 * props.height}em`,
    cursor: "pointer"
  }

  const renderPhone = () => {
    if ( ! props.details.phone_number) return "";
    return(
      <div className="phone-wrapper">
        <PhoneInput
          disabled={true}
          value={props.details.phone_number}
          onChange={() => {}}
        />
      </div>
    );
  }

  const renderOrigin = () => {
    if ( ! props.details.origin) return "";
    if(props.details.origin === "web")
      return <img className="origin-logo" src={web} />;
    if(props.details.origin === "facebook")
      return <img className="origin-logo" src={facebook} />;
    if(props.details.origin === "instagram")
      return <img className="origin-logo" src={instagram} />;
  }

  return (
    <div ref={drag} style={style} className="block">
      <div className="booking-covers" style={{"width":props.headwidth, "minWidth":props.headwidth}}>
        {props.details.group_size}
      </div>
      <div className="booking-details">
        {props.details.first_name}
        {" "}
        {props.details.last_name}
      </div>
      {renderPhone()}
      {renderOrigin()}
    </div>
  );
};
