const languages = {
  options: [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'Française' },
    { value: 'ar', label: 'عربى' },
    { value: 'it', label: 'Italiano' },
    { value: 'pl', label: 'Polskie' },
    { value: 'de', label: 'Deutsche' },
    { value: 'sv', label: 'Svenska' },
    { value: 'no', label: 'Norsk' },
    { value: 'fi', label: 'Suomalainen' },
    { value: 'ru', label: 'русский' },
    { value: 'pt', label: 'Português' },
    { value: 'el', label: 'Ελληνικά' },
  ],
  en: {
    request_to_book: "Request to book a table at",
    group_size: "Group Size",
    more: "More",
    people: "People",
    today: "Today",
    tomorrow: "Tomorrow",
    date: "Date",
    name: "Name",
    surname: "Surname",
    love_instagram: "We would love to follow you on Instagram",
    insta_handle: "@ Insta Handle",
    mobile_placeholder: "Your Mobile Number",
    additional_info_placeholder: "Allergies? Special Requests? Celebrating?",
    send_confirmation_sms: "Send confirmation SMS",
    request_button: "Request to Book",
    no_confirmation_sms: "Don't send confirmation SMS",
  },
  es: {
    request_to_book: "Solicite reservar una mesa en",
    group_size: "Personas",
    more: "Mas",
    people: "Personas",
    today: "Hoy",
    tomorrow: "Mañana",
    date: "Fecha",
    name: "Nombre",
    surname: "Apellido",
    love_instagram: "Nos encantaría seguirte en Instagram",
    insta_handle: "@ Insta Usuario",
    mobile_placeholder: "Tu Móvil",
    additional_info_placeholder: "¿Alergias? ¿Solicitudes especiales? ¿Celebrando?",
    send_confirmation_sms: "Enviar mensaje de confirmación",
    request_button: "Solicitar reserva",
    no_confirmation_sms: "No mensaje de confirmación",
  },
  fr: {
     request_to_book: "Demande de réservation de table à",
     group_size: "Taille du groupe",
     more: "Plus",
     people: "Gens",
     today: "Aujourd'hui",
     tomorrow: "Demain",
     date: "Date",
     name: "Nom",
     surname: "Nom de famille",
     love_instagram: "Nous serions ravis de vous suivre sur Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Votre numéro de mobile",
     additional_info_placeholder: "Allergies? Demandes spéciales? Célébrer?",
     request_button: "Demande de réservation",
   },
   ar: {
     request_to_book: "طلب حجز طاولة في",
     group_size: "حجم المجموعة",
     more: "أكثر",
     people: "اشخاص",
     today: "اليوم",
     tomorrow: "غدا",
     date: "تاريخ",
     name: "اسم",
     surname: "لقب",
     love_instagram: "نود أن نتابعك على Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "رقم هاتفك",
     additional_info_placeholder: "الحساسية؟ طلبات خاصة؟ الاحتفال؟",
     request_button: "طلب حجز",
   },
   it: {
     request_to_book: "Richiesta di prenotare un tavolo a",
     group_size: "Persone",
     more: "Più",
     people: "Persone",
     today: "Oggi",
     tomorrow: "Domani",
     date: "Data",
     name: "Nome",
     surname: "Cognome",
     love_instagram: "Sarebbe un piacere seguirti su Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Il tuo cellulare",
     additional_info_placeholder: "Allergie? Richieste Speciali? Celebrando?",
     request_button: "Richiedere prenotare",
   },
   pl: {
     request_to_book: "Prośba o rezerwację stolika pod adresem",
     group_size: "Wielkość grupy",
     more: "Więcej",
     people: "Ludzie",
     today: "Dzisiaj",
     tomorrow: "Jutro",
     date: "Data",
     name: "Imię",
     surname: "Nazwisko",
     love_instagram: "Chcielibyśmy Cię śledzić na Instagramie",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Twój numer komórkowy",
     additional_info_placeholder: "Alergie? Specjalna prosba? Świętujesz?",
     request_button: "Prośba o rezerwację",
   },
   de: {
     request_to_book: "Bitte um Tischreservierung bei",
     group_size: "Gruppengröße",
     more: "Mehr",
     people: "Menschen",
     today: "Heute",
     tomorrow: "Morgen",
     date: "Datum",
     name: "Name",
     surname: "Nachname",
     love_instagram: "Wir würden uns freuen, Ihnen auf Instagram zu folgen",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Deine Telefonnummer",
     additional_info_placeholder: "Allergien? Spezialwünsche? Feiern?",
     request_button: "Bitte um Buchung",
   },
   sv: {
     request_to_book: "Begäran att boka bord kl",
     group_size: "Gruppstorlek",
     more: "Mer",
     people: "Människor",
     today: "I dag",
     tomorrow: "I morgon",
     date: "Datum",
     name: "Namn",
     surname: "Efternamn",
     love_instagram: "Vi skulle gärna följa dig på Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Ditt mobilnummer",
     additional_info_placeholder: "Allergier? Speciella önskemål? Fira?",
     request_button: "Begäran att boka",
   },
   no: {
     request_to_book: "Forespørsel om å bestille bord kl",
     group_size: "Gruppestørrelse",
     more: "Mer",
     people: "Mennesker",
     today: "I dag",
     tomorrow: "I morgen",
     date: "Dato",
     name: "Navn",
     surname: "Etternavn",
     love_instagram: "Vi vil gjerne følge deg på Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Mobil nummeret ditt",
     additional_info_placeholder: "Allergi? Spesielle forespørsler? Feirer?",
     request_button: "Forespørsel om å bestille",
   },
   fi: {
     request_to_book: "Pyydä varata pöytä osoitteessa",
     group_size: "Ryhmän koko",
     more: "Lisää",
     people: "Ihmiset",
     today: "Tänään",
     tomorrow: "Huomenna",
     date: "Päivämäärä",
     name: "Nimi",
     surname: "Sukunimi",
     love_instagram: "Haluamme mielellämme seurata sinua Instagramissa",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Matkapuhelinnumerosi",
     additional_info_placeholder: "Allergiat? Erikoispyynnöt? Eläköön?",
     request_button: "Varauspyyntö",
   },
   ru: {
     request_to_book: "Просьба забронировать столик на",
     group_size: "Размер группы",
     more: "Больше",
     people: "люди",
     today: "Cегодня",
     tomorrow: "Завтра",
     date: "Дата",
     name: "название",
     surname: "Фамилия",
     love_instagram: "Мы будем рады подписаться на вас в Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Твой номер мобильного",
     additional_info_placeholder: "Аллергии? Особые запросы? Отмечают?",
     request_button: "Запрос на бронирование",
   },
   pt: {
     request_to_book: "Solicite para reservar uma mesa em",
     group_size: "Tamanho do grupo",
     more: "Mais",
     people: "Pessoas",
     today: "Hoje",
     tomorrow: "Amanhã",
     date: "Encontro",
     name: "Nome",
     surname: "Sobrenome",
     love_instagram: "Gostaríamos muito de segui-lo no Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "O teu número de telemóvel",
     additional_info_placeholder: "Alergias? Requerimentos especiais? A comemorar?",
     request_button: "Pedido de Reserva",
   },
   el: {
     request_to_book: "Αίτημα για κράτηση τραπεζιού στη διεύθυνση",
     group_size: "Μέγεθος ομάδας",
     more: "Περισσότερο",
     people: "Ανθρωποι",
     today: "Σήμερα",
     tomorrow: "Αύριο",
     date: "Ημερομηνία",
     name: "Ονομα",
     surname: "Επώνυμο",
     love_instagram: "Θα θέλαμε να σας ακολουθήσουμε στο Instagram",
     insta_handle: "@ Insta Handle",
     mobile_placeholder: "Το νούμερο του κινητού σου τηλεφώνου",
     additional_info_placeholder: "Αλλεργίες? Ειδικά αιτήματα? Γιορτάζουμε?",
     request_button: "Αίτημα για κράτηση",
   },
}

export default languages;

/*
Request to book a table at
Group Size
More
Today
Tomorrow
Date
Name
Surname
We would love to follow you on Instagram
Your Mobile Number
Allergies? Special Requests? Celebrating?
Request to Book
*/
