import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SizeMe } from 'react-sizeme'
import GridBooking from "components/GridBooking";
import DropZone from "components/DropZone";
import BeatLoader from "react-spinners/BeatLoader";
import DatePicker from 'react-datepicker';
import { parse } from "flatted/cjs";
import moment from "moment";
import axios from "axios";
import "assets/css/Spacetime.css";

const dateFormat = "YYYY-MM-DD";

// fill order should be shared accross tables and combinations
const TMP_combinations = [
  {tables: ["T2", "T3"], capacity: 4, fill_order: 11},
  {tables: ["T4", "T5"], capacity: 4, fill_order: 13},
  {tables: ["T5", "T6"], capacity: 4, fill_order: 14},
]

export default class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format(dateFormat),
      sittings: [],
      times: [],
      tables: [],
      combinations: TMP_combinations,
      bookings: null, // null, as opposed to empty, to show loading of table
      dropZones: [],
      draggingBooking: null,
    }
    this.accessString = localStorage.getItem('JWT');
  }

  componentDidMount() {
    this.getTimesAndTables(0);
    this.getBookings(this.state.date);
  }

  async getTimesAndTables(sittingIndex) {
    let response = await axios.get(`getClientSettings`,
      { headers: { Authorization: `JWT ${this.accessString}` }},
    );

    // Times
    let weekDay = moment().format("dddd").toLowerCase();
    let opening_hours = parse(response.data.maitred.opening_hours);
    let selected_sittings = opening_hours.normal[weekDay];
    let sittings = selected_sittings.sittings;
    let sitting = selected_sittings.sittings[sittingIndex];
    let times = [];
    let i = sitting.opens;
    while(parseInt(i.replace(":", "")) < parseInt(sitting.closes.replace(":", ""))) {
      times.push(i);
      i = moment(i, "HH:mm").add(15, 'minutes').format("HH:mm");
    }
    times.push(moment(i, "HH:mm").add(15, 'minutes').format("HH:mm"));
    times.push(moment(i, "HH:mm").add(30, 'minutes').format("HH:mm"));
    times.push(moment(i, "HH:mm").add(45, 'minutes').format("HH:mm"));

    // Tables
    const tables = response.data.maitred.tables ? response.data.maitred.tables : [];

    // Set state
    this.setState({ sittings: sittings, times: times, tables: tables });
  }

  async getBookings(date) {
    let response = await axios.get(`getClientBookings?date=${date}`,
      { headers: { Authorization: `JWT ${this.accessString}` }},
    );
    this.setState({ bookings: response.data });
  }

  updateBookingTables(tables) {
    let update = axios.post(
      `seatBookings`,
      { newSeatings: [
        { _id: this.state.draggingBooking._id, tables: tables },
      ] },
      { headers: { Authorization: `JWT ${this.accessString}` }}
    );
    let updatedBookings = this.state.bookings;
    updatedBookings.filter(
      booking => booking._id === this.state.draggingBooking._id
    ).pop().tables = tables;
    this.setState({
      bookings: updatedBookings,
      dropZones: [],
      draggingBooking: null
    });
  }

  displayOrderFrom(tableName) {
    let order = this.state.tables.filter(table => {
      return table.name === tableName;
    }).pop();
    return (typeof(order) === "undefined") ? 0 : parseInt(order.display_order);
  }

  orderTables(tables) {
    let so = this;
    return tables.sort((a, b) => {
      return (so.displayOrderFrom(a) < so.displayOrderFrom(b)) ? -1 : 1;
    });
  }

  splitTables(tables) {
    let orderedTables = this.orderTables(tables);
    let i;
    let o;
    for (i = 0; i < orderedTables.length; i++) {
      if(i > 0 && this.displayOrderFrom(orderedTables[i]) !== o+1) {
        return true;
      }
      o = this.displayOrderFrom(orderedTables[i]);
    }
    return false;
  }

  durationBlocks(duration) {
    if(typeof(duration) === "undefined") duration = "2:00";
    return ((parseInt(duration.split(":")[0]) * 60) + parseInt(duration.split(":")[1])) / 15;
  }

  tableIsFirst(table, bookingTables) {
    return this.orderTables(bookingTables).indexOf(table) === 0
  }

  getEndTimeUnix(startTime, duration) {
    if(typeof(duration) === "undefined") duration = "2:00";
    return moment(startTime, "HH:mm")
            .add(moment(duration, "HH:mm").get("hours"), "hours")
            .add(moment(duration, "HH:mm").get("minutes"), "minutes")
            .unix();
  }

  isFree(tables, forBooking) {
    // Space - Y: Bookings on this board intersecting with these tables
    let otherBookingsIntersectingTables = this.seatedBookings().filter(booking => {
      if(booking._id === forBooking._id) return false;
      return !!tables.filter(table => booking.tables.includes(table)).length;
    });
    // Time - X: Would any other booking using these tables clash?
    let clashingBookings = otherBookingsIntersectingTables.filter(booking => {
      return (
        (moment(booking.time, "HH:mm").unix() < this.getEndTimeUnix(forBooking.time, forBooking.duration)
        && this.getEndTimeUnix(booking.time, booking.duration) > moment(forBooking.time, "HH:mm").unix())
        ||
        (moment(forBooking.time, "HH:mm").unix() < this.getEndTimeUnix(booking.time, booking.duration)
        && this.getEndTimeUnix(forBooking.time, forBooking.duration) > moment(booking.time, "HH:mm").unix())
      );
    });
    // Bang Bang Clash
    return clashingBookings.length > 0 ? false : true;
  }

  showDropZones(forBooking) {
    this.setState({draggingBooking: forBooking})
    let possibleDropZones = this.state.tables.map(table => {
      return { tables: [table.name], capacity: table.capacity, fill_order: table.fill_order };
      })
      .concat(this.state.combinations)
      .filter(tableOrComb => {
        //if(tableOrComb.t)
        return tableOrComb.capacity >= forBooking.group_size;
      })
      .filter(tableOrComb => {
        return this.isFree(tableOrComb.tables, forBooking);
      })
      .sort((a, b) => {
        return (a.fill_order > b.fill_order ? -1 : 1);
      });
    this.setState({ dropZones: possibleDropZones });
  }

  placeDropzones(table, time, size) {
    let cellWidth = (size.width / this.state.times.length) * 0.93;
    return this.state.dropZones.map(dropZone => {
      if(dropZone.tables.includes(table)) {
        // Render complete drop zone block
        if(this.tableIsFirst(table, dropZone.tables) && ! this.splitTables(dropZone.tables)) {
          return (
            <DropZone
              updateBookingTables={() => this.updateBookingTables(dropZone.tables)}
              width={`${cellWidth * this.durationBlocks(this.state.draggingBooking.duration)}px`}
              height={dropZone.tables.length}
            />
          );
        } else if (this.tableIsFirst(table, dropZone.tables)) {
          return (
            <DropZone
              updateBookingTables={() => this.updateBookingTables(dropZone.tables)}
              width={`${cellWidth * this.durationBlocks(this.state.draggingBooking.duration)}px`}
              height="1"
            />
          );
        } else if ( ! this.tableIsFirst(table, dropZone.tables) && this.splitTables(dropZone.tables)) {
          return (
            <DropZone
              updateBookingTables={() => this.updateBookingTables(dropZone.tables)}
              width={`${cellWidth * this.durationBlocks(this.state.draggingBooking.duration)}px`}
              height="1"
            />
          );
        }
      }
    });
  }

  seatedBookings() {
    if( ! this.state.bookings) return [];
    return this.state.bookings.filter(booking => {
      if(typeof(booking.tables) === "undefined" || booking.tables === null) return false;
      return !! this.state.tables.map(table => table.name).filter(table =>
        booking.tables.includes(table)
      ).length;
    });
  }

  unseatedBookings() {
    if( ! this.state.bookings) return [];
    return this.state.bookings.filter(booking => {
      if(typeof(booking.tables) === "undefined" || booking.tables === null) return true;
      return ! this.state.tables.map(table => table.name).filter(table =>
        booking.tables.includes(table)
      ).length;
    });
  }

  placeSeatedBookings(table, time, size) {
    let cellWidth = (size.width / this.state.times.length) * 0.93;
    return this.seatedBookings().map(booking => {
      if(booking.tables.includes(table) && booking.time === time) {
        // Render complete table block
        if(this.tableIsFirst(table, booking.tables) && ! this.splitTables(booking.tables)) {
          return (
            <GridBooking
              key={`booking.${table}.${time}`}
              details={booking}
              showDropZones={() => this.showDropZones(booking)}
              headwidth={`${cellWidth}px`}
              width={`${cellWidth * this.durationBlocks(booking.duration)}px`}
              height={booking.tables.length}
            />
          );
        } else if (this.tableIsFirst(table, booking.tables)) {
          return (
            <GridBooking
              details={booking}
              showDropZones={() => this.showDropZones(booking)}
              headwidth={`${cellWidth}px`}
              width={`${cellWidth * this.durationBlocks(booking.duration)}px`}
              height="1"
            />
          );
        } else if ( ! this.tableIsFirst(table, booking.tables) && this.splitTables(booking.tables)) {
          let satelliteTable = {};
          satelliteTable.group_size = <i className={`fas fa-chevron-up`}></i>;
          satelliteTable.first_name = <i>with table {this.orderTables(booking.tables)[0]}</i>;
          return (
            <GridBooking
              details={satelliteTable}
              showDropZones={() => this.showDropZones(booking)}
              headwidth={`${cellWidth}px`}
              width={`${cellWidth * this.durationBlocks(booking.duration)}px`}
              height="1"
            />
          );
        }
      }
    });
  }

  placeUnseatedBookings(id, time, size) {
    let cellWidth = (size.width / this.state.times.length) * 0.93;
    return this.unseatedBookings().map((booking, i) => {
      if(id === booking._id && booking.time === time) {
        return (
          <GridBooking
            key={`booking.${booking._id}.${time}`}
            details={booking}
            showDropZones={() => this.showDropZones(booking)}
            headwidth={`${cellWidth}px`}
            width={`${cellWidth * this.durationBlocks(booking.duration)}px`}
            height="1"
          />
        );
      }
    });
  }

  renderSchematicGrid() {
    return (
      <SizeMe>{({ size }) =>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr><th></th>{this.state.times.map(time => {return (<th key={`title.${time}`}>{time}</th>);})}</tr>
          {this.state.tables.map(table => {
            return (<tr key={`table.${table.name}`}>
              <th className="table-name">{table.name}<span className="table-capacity"> • {table.capacity}</span></th>
              {this.state.times.map(time => {
                return (
                  <td key={`${table.name}.${time}`}>
                    <div className="fixer">
                      {(this.state.draggingBooking && this.state.draggingBooking.time === time)
                        ? this.placeDropzones(table.name, time, size) : ""}
                      {this.placeSeatedBookings(table.name, time, size)}
                    </div>
                  </td>
                );
              })}
            </tr>);
          })}
        </tbody>
      </table>
      }</SizeMe>
    );
  }

  renderAbstractedGrid() {
    return (
      <SizeMe>{({ size }) =>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          {this.unseatedBookings().map((booking, i) => {
            return (<tr key={`untable.${i}`}>
              <th className="table-name"> • </th>
              {this.state.times.map(time => {
                return (
                  <td key={`un.${booking._id}.${time}`}>
                    <div className="fixer">
                      {this.placeUnseatedBookings(booking._id, time, size)}
                    </div>
                  </td>
                );
              })}
            </tr>);
          })}
        </tbody>
      </table>
      }</SizeMe>
    );
  }

  showBookingsOnDate(date) {
    this.setState({ date: date, bookings: null });
    this.getBookings(date);
  }

  selectedCalendarDate(date) {
    return moment(this.state.date, dateFormat).calendar(null,{
      lastDay : '[Yesterday]',
      sameDay : '[Today]',
      nextDay : '[Tomorrow]',
      lastWeek : '[Last] dddd',
      nextWeek : 'dddd',
      sameElse : 'MMM Do'
    });
  }

  switchSitting(toIndex) {
    this.getTimesAndTables(toIndex);
  }

  renderSittingButtons() {
    return this.state.sittings.map((sitting, i) => {
      return (
        <td><Button
          size="lg"
          className="btn-sitting"
          onClick={() => this.switchSitting(i)}>
          {sitting.name}</Button>
        </td>
      );
    });
  }

  render() {
    if( ! this.state.times.length) return <div className="loading"><BeatLoader size={50} /></div>;
    return (
      <div className="spacetime-container">

      <table className="date-navigator">
        <tbody>
        <tr>
        <td><Button
          size="lg"
          onClick={date => this.showBookingsOnDate(moment(this.state.date, dateFormat).add(-1, 'day').format(dateFormat))}>
          <i className="fas fa-arrow-circle-left"></i></Button></td>
        <td><DatePicker
          placeholderText={this.selectedCalendarDate()}
          onChange={date => this.showBookingsOnDate(moment(date).format(dateFormat))}
        /></td>
        <td><Button
          size="lg"
          onClick={date => this.showBookingsOnDate(moment(this.state.date, dateFormat).add(1, 'day').format(dateFormat))}>
          <i className="fas fa-arrow-circle-right"></i></Button></td>
        {this.renderSittingButtons()}
        </tr>
        </tbody>
      </table>

      <DndProvider backend={HTML5Backend}>
        <div className="spacetime">
          {this.renderSchematicGrid()}
          {this.state.tables.length ? <br /> : ""}
          {this.renderAbstractedGrid()}
        </div>
      </DndProvider>

      </div>
    );
  }
}
