// Customer list view

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Customer from 'views/Customer';
import BeatLoader from "react-spinners/BeatLoader";
import ReactiveTable from "components/ReactiveTable";
import MobileTable from "components/MobileTable";
import LanguageContext from "LanguageContext";
import axios from "axios";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustomerPopup: false,
      isLoading: true,
      customers: [],
      viewportWidth: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const accessString = localStorage.getItem('JWT');
    const response = await axios.get("getClientCustomers", {
      headers: { Authorization: `JWT ${accessString}` },
    });
    this.setState({
      isLoading: false,
      customers: response.data
    });
  }

  updateWindowDimensions() {
    this.setState({ viewportWidth: window.innerWidth});
  }

  showCustomer(id) {
    this.setState({ showCustomerPopup: id })
  }

  customerPopup() {
    return (
      <Modal show={this.state.showCustomerPopup} className="customer-popup">
        <Button className="btn-close" variant="danger" onClick={() => this.setState({ showCustomerPopup: false })}>✘</Button>
        <Modal.Body>
          <Customer id={this.state.showCustomerPopup} />
        </Modal.Body>
      </Modal>
    )
  }

  getColumns(language) {
    return [
      {
        Header: language.columns.name,
        accessor: 'first_name',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.surname,
        accessor: 'last_name',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.mobile,
        type: "phone_number",
        accessor: 'phone_number',
        filter: 'StringColumnFilter',
      },
      {
        Header: language.columns.latest_visit,
        accessor: 'recent_visit_unix',
        type: 'calendar',
      },
      {
        Header: language.columns.number_of_bookings,
        accessor: 'bookings',
        type: 'emphasise',
      }
    ];
  }

  render() {
    if(this.state.isLoading) return <div className="loading"><BeatLoader size={50} /></div>;
    return (
      <LanguageContext.Consumer>{language => (
        <>
        {this.state.viewportWidth < 1000 ?
          <MobileTable
            type="client"
            rowClick={(id) => this.showCustomer(id)}
            columns={this.getColumns(language)}
            data={this.state.customers}
          />
          :
          <ReactiveTable
            rowClick={(id) => this.showCustomer(id)}
            columns={this.getColumns(language)}
            data={this.state.customers}
          />
        }
        {this.customerPopup()}
        </>
      )}</LanguageContext.Consumer>
    );
  }
}

export default Customers;
