export const languages = {
  options: [
    { value: 'en', label: '🇬🇧 English' },
    { value: 'es', label: '🇪🇸 Español' },
  ],
  en: {
    is: "en",
    external: {
      good: "Looks good!",
      name: "Name",
      surname: "Surname",
      phone: "Phone",
      message: "Message",
      contact: "Contact",
      login: "Login",
      register: "Register",
      email: "Email",
      email_placeholder: "Enter email address",
      password: "Password",
      login_button: "Login",
      currency_region: "🌎. Currency & Region",
      your_name: "Your Name",
      your_establishment: "Your Establishment",
      your_establishment_placeholder: "Enter the name of your business",
      your_email: "Your Email",
      your_email_placeholder: "Email Address",
      your_email_subtext: "For signing in and resetting passwords",
      your_phone: "Your Phone Number",
      your_phone_placeholder: "Mobile or Landline",
      choose_password: "Choose a Password",
      password_error: "Password must be at least 6 characters long",
      credit_debit: "Credit / Debit Card",
      card_subtext: "You will not be charged anything now",
      get_started: "Lets get started!",
      login_incorrect: "Invalid email address or incorrect password",
      reset_password: "Reset password",
      enter_registered: "Please enter the email address you registered with",
      email_password: "Email New Password",
    },
    navigation: {
      new: "New",
      overview: "Overview",
      spacetime: "Spacetime",
      bookings: "Bookings",
      customers: "Customers",
      analytics: "Analytics",
      settings: "Settings",
      account: "Account",
    },
    columns: {
      for: "For",
      type: "Type",
      requested: "Requested",
      arriving: "Arriving",
      covers: "Covers",
      date: "Date",
      time: "Time",
      name: "Name",
      surname: "Surname",
      nationality: "Nationality",
      mobile: "Mobile",
      created: "Created",
      origin: "Origin",
      latest_visit: "Latest Visit",
      number_of_bookings: "Bookings",
      additional_info: "Additional Info"
    },
    common: {
      search: "Filter",
      confirmed: "Confirmed",
      unconfirmed: "Unconfirmed",
      accept: "Accept",
      offer_alternatives: "Offer Alternative Times",
      offer_times: "Offer Times",
      fully_booked: "Fully Booked",
      add_waiting: "Add to Waiting List",
      waiting_list: "Waiting List",
      logout: "Logout",
    },
    datetimes: {
      lastDay : '[Yesterday] @ HH:mm',
      sameDay : '[Today] @ HH:mm',
      nextDay : '[Tomorrow] @ HH:mm',
      lastWeek : '[Last] dddd @ HH:mm',
      nextWeek : 'dddd @ HH:mm',
      sameElse : 'MMM Do @ HH:mm',
    },
    dates: {
      lastDay : '[Yesterday]',
      sameDay : '[Today]',
      nextDay : '[Tomorrow]',
      lastWeek : '[Last] dddd',
      nextWeek : 'dddd',
      sameElse : 'MMM Do',
    },
    analytics: {
      bookings_per_weekday : 'Average bookings per day',
      covers_per_booking : 'Covers per booking',
    },
    settings: {
      page_size_limit: "To modify settings please view this page in a larger screen",
      opening_hours: "Opening Hours",
      regular_opening_hours: "Regular Opening Hours",
      weekdays: {
        monday: "monday",
        tuesday: "tuesday",
        wednesday: "wednesday",
        thursday: "thursday",
        friday: "friday",
        saturday: "saturday",
        sunday: "sunday",
      },
      sitting_placeholder: "Sitting Name",
      from: "From",
      until: "Until",
      auto_confirm: "Auto Confirm",
      capacity: "Capacity",
      max_group_size: "Max Group Size",
      duration: "Turnaround",
      capacity_placeholder: "Capacity",
      save: "Save",
      update: "Update",
      delete: "Delete",
      add_sitting: "Add Sitting",
      maitred_form: "Maitre'D Form",
      booking_link_web: "Booking Link for Web",
      booking_link_facebook: "Booking Link for Facebook",
      booking_link_instagram: "Booking Link for Instagram",
    },
    sms: {
      dummy_booking: "Dummy Booking",
      sms_confirm: "Confirmation Massage",
      sms_confirm_button: "Update Confirmation Message",
      example_sms_confirm: "Example Confirmation Message",
      sms_counter: "Counter Offer Message",
      sms_counter_button: "Update Counter Offer Message",
      example_sms_counter: "Example Counter Offer Message",
      sms_reject: "Fully Booked Message",
      sms_reject_button: "Update Fully Booked Message",
      example_sms_reject: "Example Fully Booked Message",
      characters_used: "characters used",
      character_counter: "{{USED}} of {{LIMIT}} characters used",
      character_exceded: "{{LIMIT}} character limit exceeded by {{OVERAGE}} characters",
    },
    account: {
      details: "Details",
      stripe: "Payments",
      stripe_description: "To accept deposits and card guarantees",
      stripe_connected_description: "Accepting deposits & guarantees via connected Stripe account",
      sms_usage: "SMS Usage",
      sms_usage_explanation: "The Resbee platform itself is free, however we do charge on a monthly basis for SMS text messages that you use to interact with your guests and confirm bookings at {{ESTABLISHMENT}} - and the more messages you use each month, the cheaper they become. So far {{MESSAGE_COUNT}} SMS messages have been used this month.",
      tier_pricing: [
        {title: "First 33 messages", price: "£1"},
        {title: "Messages 34 to 66", price: "80p"},
        {title: "Messages 67 to 99", price: "60p"},
        {title: "Messages 100 +", price: "40p"},
      ],
      per_sms: "per SMS",
      security: "Security",
      change_password: "Change Password",
      current_password: "Current Password",
      new_password: "New Password",
      repeat_password: "Repeat New Password",
    }
  },
  es: {
    is: "es",
    external: {
      good: "¡Se ve bien!",
      name: "Nombre",
      surname: "Apellido",
      phone: "Teléfono",
      message: "Mensaje",
      contact: "Contacta",
      login: "Accede",
      register: "Regístrate",
      email: "Email",
      email_placeholder: "Direccion de correo",
      password: "Contraseña",
      login_button: "Entrar",
      currency_region: "🌎. Tipo de Moneda y Región",
      your_name: "Tu Nombre",
      your_establishment: "Tu establecimiento",
      your_establishment_placeholder: "Nombre de tu establecimiento",
      your_email: "Tu email",
      your_email_placeholder: "Dirección de tu correo electrónico",
      your_email_subtext: "Para acceder y para resetear tu contraseña",
      your_phone: "Tu telefono",
      your_phone_placeholder: "Móvil o Fijo",
      choose_password: "Elije una contraseña",
      password_error: "La contraseña debe contener 6 caracteres como mínimo",
      credit_debit: "Tarjeta de Credito / Debito",
      card_subtext: "No se te hara ningun cargo ahora",
      get_started: "Empezamos!",
      login_incorrect: "Dirección de correo electrónico no válida o contraseña incorrecta",
      reset_password: "Restablecer la contraseña",
      enter_registered: "Ingrese la dirección de correo electrónico con la que se registró",
      email_password: "Enviar nueva contraseña",
    },
    navigation: {
      new: "Nueva",
      overview: "Panal",
      spacetime: "Spacetime",
      bookings: "Reservas",
      customers: "Clientes",
      analytics: "Analítica",
      settings: "Herramientas",
      account: "Tu perfil",
    },
    columns: {
      for: "Para",
      type: "Tipo",
      requested: "Pedida",
      arriving: "Llegada",
      covers: "Pers.",
      date: "Fecha",
      time: "Hora",
      name: "Nombre",
      surname: "Apellido",
      nationality: "Nacionalidad",
      mobile: "Móvil",
      created: "Creado",
      origin: "Origen",
      latest_visit: "Última visita",
      number_of_bookings: "Reservas",
      additional_info: "Información adicional"
    },
    common: {
      search: "Buscar",
      confirmed: "Confirmadas",
      unconfirmed: "No confirmadas",
      accept: "Aceptar",
      offer_alternatives: "Ofrecer horarios alternativos",
      offer_times: "Ofrecer horarios",
      fully_booked: "Agotado",
      add_waiting: "Agregar a la lista de espera",
      waiting_list: "Lista de espera",
      logout: "Salir",
    },
    datetimes: {
      lastDay : '[Ayer] - HH:mm',
      sameDay : '[Hoy] - HH:mm',
      nextDay : '[Mañana] - HH:mm',
      lastWeek : 'dddd [pasado] - HH:mm',
      nextWeek : 'dddd - HH:mm',
      sameElse : 'MMM D - HH:mm',
    },
    dates: {
      lastDay : '[Ayer]',
      sameDay : '[Hoy]',
      nextDay : '[Mañana]',
      lastWeek : 'dddd [pasado]',
      nextWeek : 'dddd',
      sameElse : 'MMM D',
    },
    analytics: {
      bookings_per_weekday : 'Reservas promedio por día',
      covers_per_booking : 'Clientes por reserva',
    },
    settings: {
      page_size_limit: "Para modificar la configuración, vea esta página en una pantalla más grande",
      opening_hours: "Horarios de apertura",
      regular_opening_hours: "Horarios regulares de apertura",
      weekdays: {
        monday: "lunes",
        tuesday: "martes",
        wednesday: "miércoles",
        thursday: "jueves",
        friday: "viernes",
        saturday: "sábado",
        sunday: "domingo",
      },
      sitting_placeholder: "Almuerzo / Cena / Brunch",
      from: "De",
      until: "Hasta",
      auto_confirm: "Auto-Confirmación",
      capacity: "Aforo máximo total",
      max_group_size: "Aforo máximo por mesa",
      duration: "Duracion máx. por mesa",
      capacity_placeholder: "Num. personas",
      save: "Salvar",
      update: "Actualizar",
      delete: "Borrar",
      add_sitting: "Añadir Servicio",
      maitred_form: "Plantilla de Reservas",
      booking_link_web: "Plataforma para tu web",
      booking_link_facebook: "Plataforma para Facebook",
      booking_link_instagram: "Plataforma para Instagram",
    },
    sms: {
      dummy_booking: "Vistra previa con ejemplo de reserva",
      sms_confirm: "Mensaje de confirmación",
      sms_confirm_button: "Actualizar Mensaje de confirmación",
      example_sms_confirm: "Ejemplo mensaje de confirmación",
      sms_counter: "Mensaje de contra oferta de hora",
      sms_counter_button: "Actualizar mensaje de contra oferta",
      example_sms_counter: "Ejemplo mensaje de contra oferta",
      sms_reject: "Mensaje de Lista de Espera",
      sms_reject_button: "Actualizar mensaje de Lista de Espera",
      example_sms_reject: "Ejemplo mensaje de Lista de espera",
      character_counter: "{{USED}} de {{LIMIT}} caracteres usados",
      character_exceded: "Límite de {{LIMIT}} caracteres excedido por {{OVERAGE}} caracteres",
    },
    account: {
      details: "Tus datos de contacto",
      stripe: "Pagos",
      stripe_description: "Para aceptar depósitos y garantías de tarjetas",
      stripe_connected_description: "Aceptar pagos y garantías de tarjetas a través de la cuenta de Stripe",
      sms_usage: "Tu uso mensual de SMS",
      sms_usage_explanation: "La plataforma Resbee es gratuita, pero cobramos mensualmente por los mensajes de texto SMS que usas para interactuar con tus huéspedes y confirmar las reservas en {{ESTABLISHMENT}} - y cuantos más mensajes uses cada mes, más baratos serán. Hasta ahora se han utilizado {{MESSAGE_COUNT}} mensajes SMS este mes.",
      tier_pricing: [
        {title: "1 a 33 mensajes SMS", price: "1€"},
        {title: "De 34 a 66 mensajes", price: "0,80€"},
        {title: "De 67 a 99 mensajes", price: "0,60€"},
        {title: "+100 mensajes SMS", price: "0,40€"},
      ],
      per_sms: "por mensaje",
      security: "Seguridad",
      change_password: "Cambio de contraseña",
      current_password: "Contraseña actual",
      new_password: "Nueva contraseña",
      repeat_password: "Repita la nueva contraseña",
    }
  }
};

export default languages;
