import React from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'assets/css/MobilePhone.css';

export default function MobilePhone({ 
  setPhoneNumber = () => {}, 
  placeholderText = "Enter phone number", 
  preferredCountries = ['gb', 'es']
}) {
  const [phoneNumber, setPhoneNumberLocally] = React.useState();
  const [focussed, setFocussed] = React.useState(false);

  const focusInput = e => {
    if(focussed) return;
    setFocussed(true)
    try {
      e.preventDefault();
      document.getElementsByClassName("selected-flag")[0].click();
    } catch(error) {
      return;
    }
  }

  const formatPhoneValue = (value, country, e, formattedValue) => {
    const reformattedPhoneNumber = String(+value.substring((country.dialCode.length)));
    const phoneNumberString = `${country.dialCode}${reformattedPhoneNumber > 0 && reformattedPhoneNumber}`;
    console.log('pns', phoneNumberString);
    setPhoneNumberLocally(phoneNumberString);
    setPhoneNumber(phoneNumberString);
  }

  return (
    <PhoneInput
      onFocus={(e) => focusInput(e)}
      placeholder={placeholderText}
      searchPlaceholder={""}
      containerClass={phoneNumber && "country-selected"}
      preferredCountries={preferredCountries}
      value={phoneNumber}
      enableSearch={true}
      countryCodeEditable={false}
      onChange={formatPhoneValue}
    />
  )
}
