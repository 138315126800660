import React from 'react';
import { Form, Button, Col, Modal } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import BeatLoader from "react-spinners/BeatLoader";
import OpeningHours from 'components/OpeningHours';
import PhoneInput from 'react-phone-input-2';
import DotLoader from "react-spinners/DotLoader";
import DatePicker from 'react-datepicker';
import WebFont from 'webfontloader';
import Legal from "languages/legal";
import { createGlobalStyle } from 'styled-components';
import { useForm } from 'react-hook-form';
import { parse } from "flatted/cjs";
import legalText from "languages/legalText";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/Maitred.css";
import 'react-phone-input-2/lib/style.css';

const dateFormat = "YYYY-MM-DD";
const today = moment().format(dateFormat);
const tomorrow = moment().add(1, 'day').format(dateFormat);

export default function MaitredForm(props) {

  /* Establishment Specific Styles */
  let styles_master = parse(props.setup.styles);
  let styles = {...styles_master.general, ...styles_master.buttons, ...styles_master.confirmation };

  const getStyle = style => {
    return (styles[style]) ? styles[style].value : "";
  }

  WebFont.load({
    google: {
      families: [styles.font_import.value, styles.title_font_import.value]
    }
  });

  const GlobalStyles = createGlobalStyle`
    .container {
      background-color: ${getStyle("background_color")};
      background-image: url(${getStyle("background_image")});
    }

    .maitred-form {
      background-color: ${getStyle("background_color")};
      background-image: url(${getStyle("background_image")});
      font-family: ${getStyle("font_import")}, sans-serif;
      font-size: ${getStyle("font_size")};
      color: ${getStyle("font_color")};
      text-shadow: ${getStyle("text_shadow")};
    }

    .maitred-form h2 {
      font-family: ${getStyle("title_font_import")}, sans-serif;
      font-size: ${getStyle("title_font_size")};
      color: ${getStyle("title_font_color")};
      text-shadow: ${getStyle("title_text_shadow")};
    }

    .maitred-form .nav-item {
      color: ${getStyle("button_font_color")} !important;
      background-color: ${getStyle("button_color")} !important;
      border: ${getStyle("button_border")} !important;
      border-radius: ${getStyle("button_border_radius")};
    }
    .maitred-form .nav-item.active {
      background-color: ${getStyle("button_hover_color")} !important;
    }

    .maitred-form select,
    .maitred-form button[type='submit'],
    .maitred-form .form-check label,
    .maitred-form .react-datepicker__input-container input {
      height: 100%;
      min-height: 3rem;
      width: 100%;
      border-radius: ${getStyle("button_border_radius")};
      background-color: ${getStyle("button_color")};
      color: ${getStyle("button_font_color")};
      border: ${getStyle("button_border")};
      font-size: ${getStyle("button_font_size")} !important;
      &:hover {
        background-color: ${getStyle("button_hover_color")};
      }
      &::placeholder {
        color: ${getStyle("button_font_color")};
      }
      &::-webkit-input-placeholder {
        color: ${getStyle("button_font_color")};
      }
      &:-ms-input-placeholder {
        color: ${getStyle("button_font_color")};
      }
    }

    .maitred-form .form-group input.checked,
    .maitred-form .form-check input:checked ~ label {
      background-color: ${getStyle("button_hover_color")};
    }

    .maitred-form input[name="first_name"],
    .maitred-form input[name="last_name"],
    .maitred-form input[name="instagram_handle"],
    .maitred-form input[name="additional_info"],
    .maitred-form .react-tel-input .flag-dropdown,
    .maitred-form input[name="phoneNumber"] {
      font-family: ${getStyle("font_import")}, sans-serif;
      background-color: ${getStyle("input_background_color")};
      border: ${getStyle("input_border")} !important;
      font-size: ${getStyle("button_font_size")} !important;
    }

    .maitred-form input,
    .maitred-form select,
    .maitred-form button,
    .maitred-form button[type='submit'],
    .maitred-form .form-check label,
    .maitred-form .react-datepicker__input-container input
    .maitred-form .PhoneInputCountry, {
      font-size: ${getStyle("button_font_size")} !important;
    }
  `;

  /* "handleSubmit" will validate inputs before invoking "onSubmit" */
  const { register, getValues, setValue, handleSubmit, watch, errors } = useForm();

  const wipeGroupSizeRadios = group_size => {
    setValue([{ group_size : null }]);
  }

  const onSubmit = async (data) => {
    setIsSubmitted(true);
    data["origin"] = props.origin;
    data["time"] = selectedTime;
    if( ! data["date"]) data["date"] = moment(startDate).format(dateFormat);
    data["group_size"] = (data["group_size"]) ? data["group_size"] : data["group_size_selected"];
    data["phone_number"] = `+${phoneNumber}`;
    data["confirmation_sms"] = confirmationSMS;
    data["uid"] = props.uid;
    data["internal"] = props.internal ? true : false;

    // todo: use PhoneInput validation here
    if(typeof data["phone_number"] === "undefined") {
      alert("Please enter a valid phone number");
      setIsSubmitted(false);
      return;
    }

    try {
      const response = await axios.post(
        "requestBooking",
        data
      );
      // Show success
      if(response.status == 200) props.requestSubmitted(response.data);
    } catch (error) {
      console.error(JSON.stringify(error.response));
    }
    setIsSubmitted(false);
  };

  const getSittingsForDate = date => {
    setSelectedOption(date);
    props.getSittingsForDate(date);
  }

  const specificDate = date => {
    getSittingsForDate(moment(date).format(dateFormat));
    setStartDate(date);
  }

  const handleTimeCheck = e => {
    setSelectedTime(e.target.value);
  }

  const formatPhoneValue = (value, country, e, formattedValue) => {
    let reformattedPhoneNumber = String(+value.substring((country.dialCode.length)));
    console.log('reformatted', `${country.dialCode}${reformattedPhoneNumber}`)
    setPhoneValue(`${country.dialCode}${reformattedPhoneNumber > 0 && reformattedPhoneNumber}`)
  }

  const interpolatedLegal = () => {
    const legal = legalText["en"].agreements.split("{{TERMS}}");
    const sublegal = legal[0].split("{{PRIVACY}}");
    return(
      <>
        {sublegal[0]}
        <a className="modal-link" onClick={() => setShowModal("privacy")}>{legalText["en"].privacy}<i className="fas fa-external-link-square-alt"></i></a>
        {sublegal[1]}
        <a className="modal-link" onClick={() => setShowModal("terms")}>{legalText["en"].terms}<i className="fas fa-external-link-square-alt"></i></a>
        {legal[1]}
      </>
    );
  }

  const legalModal = type => {
    return (
      <Modal className="legal-modal" show={showModal} onClick={() => setShowModal()}>
        {
          type === "privacy"
          ? <Legal establishment={props.setup.establishment_name} language="en" type="privacy" />
          : <Legal establishment={props.setup.establishment_name} language="en" type="terms" />
        }
      </Modal>
    );
  }

  const focusPhoneInput = e => {
    if(focussed) return;
    setFocussed(true)
    try {
      e.preventDefault();
      document.getElementsByClassName("selected-flag")[0].click();
    } catch(error) {
      return;
    }
  }

  const [focussed, setFocussed] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedTime, setSelectedTime] = React.useState();
  const [selectedOption, setSelectedOption] = React.useState();
  const [startDate, setStartDate] = React.useState();
  const [phoneNumber, setPhoneValue] = React.useState();
  const [confirmationSMS, setConfirmationSMS] = React.useState(true);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  return (
    <div className="maitred-form">

    { ! props.internal && <>
      <GlobalStyles />
      {getStyle("establishment_logo")
        ? <img className="establishment-logo" src={getStyle("establishment_logo")} />
        : <div className="buff"></div>}
      {props.language.request_to_book}
      <h2>{props.setup.establishment_name}</h2>
    </>}

    <Form onSubmit={handleSubmit(onSubmit)}>

    <Form.Label>{props.language.group_size}</Form.Label>
    <Form.Row className={errors.group_size && "error"}>
      {[1,2,3,4].map(groupSize => {
        return (<Form.Group as={Col} key={`groupsize-${groupSize}`}>
          <Form.Check
            name="group_size"
            type="radio"
            id={`groupsize-${groupSize}`}
            label={groupSize}
            value={groupSize}
            ref={register()}
          />
        </Form.Group>);
      })}
      <Form.Group as={Col} key="groupsize-large">
      <Form.Control
        as="select"
        name="group_size_selected"
        ref={register()}
        onChange={() => wipeGroupSizeRadios()}
        value={(getValues("group_size_selected")) ? getValues("group_size_selected") : -1}>
        <option disabled value={-1} key={-1}>{props.language.more}</option>
        {Array.from(Array((parseInt(getStyle("max_group_size")) + 1)).keys()).splice(5).map(groupSize => { return (
          <option key={`groupsize-${groupSize}`} value={groupSize}>{`${groupSize} ${props.language.people}`}</option>
        )})}
      </Form.Control>
      </Form.Group>
    </Form.Row>

    <Form.Row className={errors.date && "error"}>
      <Form.Group as={Col} key="date-today">
        <Form.Check
          checked={selectedOption === today}
          name="date"
          type="radio"
          id="date-today"
          label={props.language.today}
          value={today}
          readOnly
          onClick={(date) => getSittingsForDate(today)}
          ref={register()}
        />
      </Form.Group>
      <Form.Group as={Col} key="date-tomorrow">
        <Form.Check
          checked={selectedOption === tomorrow}
          name="date"
          type="radio"
          id="date-tomorrow"
          label={props.language.tomorrow}
          value={tomorrow}
          readOnly
          onClick={(date) => getSittingsForDate(tomorrow)}
          ref={register()}
        />
      </Form.Group>
      <Form.Group as={Col} key="date-choose">
        <DatePicker
          minDate={new Date()}
          className={(selectedOption
                      && selectedOption !== today
                      && selectedOption !== tomorrow) ? "checked" : ""}
          type="radio"
          id="date-choose"
          placeholderText={props.language.date}
          dateFormat="MMMM dd yyyy"
          value={startDate}
          selected={startDate}
          onChange={date => specificDate(date)}
          popperPlacement="left-end"
          popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        />
      </Form.Group>
    </Form.Row>

    <Form.Row className={`time-row ${errors.time && "error"}`}>
      {props.selected_opening_hours
        ? <OpeningHours
            openingHours={props.selected_opening_hours}
            handleChange={(e) => handleTimeCheck(e)} />
        : <div className="time-row-loader">
            <DotLoader size={150} color={props.internal ? "#ced4da" : styles.button_color.value}/>
          </div>
      }
    </Form.Row>

    <Form.Row className={(errors.first_name || errors.last_name) && "error"}>
      <Form.Group as={Col}>
        <Form.Control
          type="text"
          placeholder={props.language.name}
          name="first_name"
          ref={register({ required: true, minLength: 2 })}
        />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Control
          type="text"
          placeholder={props.language.surname}
          name="last_name"
          ref={register({ required: true, minLength: 2 })}
        />
      </Form.Group>
    </Form.Row>

    {props.origin === "instagram" &&
    <>
    <Form.Label>{props.language.love_instagram}</Form.Label>
    <Form.Row>
      <Form.Group as={Col}>
        <Form.Control
          className="insta-handle-input"
          type="text"
          placeholder={props.language.insta_handle}
          name="instagram_handle"
          ref={register()}
        />
      </Form.Group>
    </Form.Row>
    </>
    }

      <Form.Group className="phone-wrapper">
        <PhoneInput
          onFocus={(e) => focusPhoneInput(e)}
          placeholder={props.language.mobile_placeholder}
          searchPlaceholder={""}
          inputProps={{
            name: 'phoneNumber',
            required: true,
          }}
          containerClass={phoneNumber && "country-selected"}
          value={phoneNumber}
          onChange={formatPhoneValue}
          preferredCountries={[props.setup.country_code && props.setup.country_code.toLowerCase()]}
          enableSearch={true}
          countryCodeEditable={false}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Control
            type="text"
            placeholder={props.language.additional_info_placeholder}
            name="additional_info"
            ref={register()}
          />
        </Form.Group>
      </Form.Row>

      {props.internal ?
        <Form.Row>
          <BootstrapSwitchButton
            name="confirmation_sms"
            onlabel={props.language.send_confirmation_sms}
            offlabel={props.language.no_confirmation_sms}
            checked={confirmationSMS}
            onChange={setConfirmationSMS}
          />
        </Form.Row>
        :
        <Form.Row>
          <div className="legal">{interpolatedLegal()}</div>
          {legalModal(showModal)}
        </Form.Row>
      }

      {( ! isSubmitted) ?
      <Button variant="primary" type="submit">
        {props.language.request_button}
      </Button>
      : <div className="loading inline"><BeatLoader size={25} /></div> }
    </Form>
    </div>
  );
}
