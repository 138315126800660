const maitredSettings = {
  general: {
    establishment_logo: {label: "Logo Image Link", type: "text"},
    max_group_size: {label: "Largest Group Size", type: "number"},
    background_color: {label: "Background Colour", type: "color"},
    background_image: {label: "Background Image", type: "text"},
    font_import: {label: "Font", type: "text"},
    font_size: {label: "Font Size", type: "text"},
    font_color: {label: "Text Colour", type: "color"},
    text_shadow: {label: "Text Shadow", type: "text"},
    title_font_import: {label: "Title Font", type: "text"},
    title_font_size: {label: "Title Font Size", type: "text"},
    title_font_color: {label: "Title Text Colour", type: "color"},
    title_text_shadow: {label: "Text Shadow", type: "text"},
  },
  buttons: {
    button_color: {label: "Colour", type: "color"},
    button_hover_color: {label: "Hover & Selected Colour", type: "color"},
    button_border: {label: "Border", type: "text"},
    button_border_radius: {label: "Border Radius", type: "text"},
    button_font_size: {label: "Font Size", type: "text"},
    button_font_color: {label: "Text Colour", type: "color"},
    button_text_shadow: {label: "Text Shadow", type: "text"},
    // inputs
    input_background_color: {label: "Input Background Colour", type: "color"},
    input_border: {label: "Input Border", type: "text"},
  },
  confirmation: {
    cp_background_color: {label: "Background Colour", type: "color"},
    cp_background_image: {label: "Background Image", type: "text"},
    cp_font_import: {label: "Font", type: "text"},
    cp_font_size: {label: "Font Size", type: "text"},
    cp_font_color: {label: "Text Colour", type: "color"},
    cp_text_shadow: {label: "Text Shadow", type: "text"},
  }
}

export default maitredSettings;
