const legalText = {
  options: [
    { value: 'en', label: '🇬🇧 English' },
    { value: 'es', label: '🇪🇸 Español' },
  ],
  en: {
    agreements: "By clicking \"Request to Book\" you agree to the {{PRIVACY}} and to the {{TERMS}} of this service",
    privacy: "Privacy Policy",
    terms: "Terms & Conditions",
  }
}

export default legalText;
