// Settings page and configuration of public booking form link, opening hours etc

import React, { Component } from "react";
import { Button, Row, Col, Nav, Form, Tabs, Tab } from "react-bootstrap";
import Register from "views/Register";
import Sitting from "components/Sitting";
import BookingRequest from "views/BookingRequest";
import MaitredForm from "views/MaitredForm";
import LanguageContext from "LanguageContext";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { parse, stringify } from "flatted/cjs";
import axios from "axios";
import moment from "moment";
import maitredSettings from "resources/maitredSettings";
import languages from "languages/maitred";

const dateFormat = "YYYY-MM-DD";
const newSittingTemplate = {name: "", opens:"", closes:"", auto:"", group_size:"", capacity:"", duration:""}

const dummyBooking = {
  GROUPSIZE: 10,
  DATE: moment("2020-08-04", dateFormat).format("MMM Do"),
  TIME: "19:30",
  NAME: "Joseph",
  SURNAME: "Bloggs",
  ESTABLISHMENT: "Volt",
  LINK: "<<link>>",
}

const communication = {
  sms_confirm: {label: "Confirmation Message", type: "textarea"},
  sms_counter: {label: "Counter Offer Message", type: "textarea"},
  sms_reject: {label: "Fully Booked Message", type: "textarea"},
};

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mockup: "communication",
      baseURL: null,
      isLoading: true,
      maitred: {},
      sittings: null,
      maitredSettings: {},
      table_management: false,
      tables: [],
      viewportWidth: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accessString = localStorage.getItem('JWT');
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const response = await axios.get("getClientSettings", {
      headers: { Authorization: `JWT ${this.accessString}` },
    });
    this.setState({
      baseURL: `https://resbee.app/${response.data.profile.uid}`,
      isLoading: false,
      maitred: {
        uid: response.data.profile.uid,
        establishment_name: response.data.maitred.establishment_name,
        guests: [1,2,3,4,5,6],
        opening_hours: response.data.maitred.opening_hours,
        styles: response.data.maitred.form_styles,
      },
      maitredSettings: parse(response.data.maitred.form_styles),
      sms_confirm: response.data.maitred.sms_confirm,
      sms_counter: response.data.maitred.sms_counter,
      sms_reject: response.data.maitred.sms_reject,
      table_management: response.data.maitred.table_management,
      tables: response.data.maitred.tables ? response.data.maitred.tables.filter(table => {
        return table ? true : false;
      }) : [],
    });
  }

  updateWindowDimensions() {
    this.setState({ viewportWidth: window.innerWidth});
  }

  showChangePasswordForm() {
    this.setState({changingPassword: true});
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  changePasswordForm() {
    return(
      <Form className="reset-form" onSubmit={this.changePassword}>
        <Form.Group>
          <Form.Label>Current Password</Form.Label>
          <Form.Control type="password" onChange={this.handleChange('current_password')} />
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" onChange={this.handleChange('new_password')} />
          <Form.Label>Repeat New Password</Form.Label>
          <Form.Control type="password" onChange={this.handleChange('new_password_repeated')} />
        </Form.Group>
        <Button variant="danger" type="submit">
          Change Password
        </Button>
      </Form>
    );
  }

  changePassword = async (e) => {
    e.preventDefault()
    const { current_password, new_password, new_password_repeated } = this.state;

    this.setState({isLoading: true});

    if(new_password === new_password_repeated) {
      const response = await axios.post("changeClientPassword",
        {current_password: current_password, new_password: new_password},
        {headers: { Authorization: `JWT ${this.accessString}` }}
      );

      if(response.status == 200) {
        this.setState({
          isLoading: false,
          changingPassword: false,
        });
      } else {
        console.log(JSON.stringify(response));
        this.setState({
          isLoading: false,
          changingPassword: false,
        });
      }
    } else {
      alert("Passwords do not match!");
    }
  }

  requestSubmitted() {
    console.log("Booking request submitted internally");
  }

  getSittingsForDate(date) {
    const opening_hours = parse(this.state.maitred.opening_hours);
    let selected_sittings = opening_hours.normal[moment(date, dateFormat).locale("en").format("dddd").toLowerCase()];
    this.setState({ sittings: selected_sittings });
  }

  addSitting = (type, date) => {
    let maitred = this.state.maitred;
    let opening_hours = parse(maitred.opening_hours);
    let newSitting = newSittingTemplate;
    newSitting.id = moment().unix();
    opening_hours[type][date].sittings.push(newSitting);
    maitred.opening_hours = stringify(opening_hours);
    this.setState({ maitred: maitred });
  }

  updateOpeningHours = (type, dates, sitting, deleting) => {
    let maitred = this.state.maitred;
    let opening_hours = parse(maitred.opening_hours);
    if(deleting) {
      // Removing sitting on date
      let remainingSittings = opening_hours[type][dates].sittings.filter(checkSitting => {
        return (checkSitting.id !== sitting.id);
      });
      opening_hours[type][dates].sittings = remainingSittings;
    } else {
      // Adding or updating sitting for date
      let sittingExists = opening_hours[type][dates].sittings.filter(checkSitting => {
        return (+checkSitting.id === +sitting.id);
      }).pop();
      if(sittingExists) {
        let updateIndex = opening_hours[type][dates].sittings
                          .findIndex(updateSitting => +updateSitting.id === +sitting.id );
        opening_hours[type][dates].sittings[updateIndex] = sitting;
      } else {
        opening_hours[type][dates].sittings.push(sitting);
      }
    }
    maitred.opening_hours = stringify(opening_hours);
    this.setState({ maitred: maitred });
    this.setMaitred(false, "opening_hours");
  }

  setMaitred = async (e, setting) => {
    if(e) e.preventDefault();
    let settingValue;
    if(setting === "table_management") settingValue = this.state.table_management;
    if(setting === "opening_hours") settingValue = this.state.maitred.opening_hours;
    if(setting === "form_styles") settingValue = stringify(this.state.maitredSettings);
    if(setting.startsWith("sms_")) settingValue = this.state[setting];

    const response = await axios.post("setMaitred",
      {setting: setting, settingValue: settingValue},
      {headers: { Authorization: `JWT ${this.accessString}` }}
    );
    console.log(JSON.stringify(response));
  }

  renderSittings = (key, type, dates, day) => {
    let sittings;
    if( ! day.open) {
      sittings = <div className="sittings-closed"><span>Closed</span></div>;
    } else {
      sittings = day.sittings.map((sitting, i) => {
        return <Sitting
                  type={type}
                  dates={dates}
                  details={sitting}
                  updateOpeningHours={(type, dates, data, deleting) => this.updateOpeningHours(type, dates, data, deleting)}
                  key={`sitting-component-${i}`}
                />
      });
    }
    return <div className="sittings" key={`sitting-wrapper-${key}`}>{sittings}</div>;
  }

  renderWeekdayOpeningHours = () => {
    const opening_hours = parse(this.state.maitred.opening_hours);
    return(
      <LanguageContext.Consumer>{language => (
      <Tab.Container defaultActiveKey="1">
      <Row>
        <Col sm={2} className="weekday-opening-hours">
          <Nav variant="pills" className="flex-column">
            {["monday","tuesday","wednesday","thursday","friday","saturday","sunday"].map(day => {
              return (
                <Nav.Item key={day}>
                  <Nav.Link eventKey={day}>
                    {language.settings.weekdays[day][0].toUpperCase() + language.settings.weekdays[day].substring(1)}
                  </Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </Col>
        <Col>
          <Tab.Content className="weekdays-wrapper">
            {["monday","tuesday","wednesday","thursday","friday","saturday","sunday"].map(key => {
              let dates = key;
              return (
                <Tab.Pane className="weekday-sittings" eventKey={key} key={`tab-day${key}`}>
                  {this.renderSittings(key, "normal", dates, opening_hours.normal[key])}
                  <Button onClick={() => this.addSitting("normal", dates)}>
                    {language.settings.add_sitting}
                  </Button>
                </Tab.Pane>
              )
            })}
          </Tab.Content>
        </Col>
      </Row>
      </Tab.Container>
      )}</LanguageContext.Consumer>
    );
  }

  handleMaitredChange = (category, name) => (event) => {
    let updatedMaitredSettingsState = this.state.maitredSettings;
    console.log(updatedMaitredSettingsState)
    if(updatedMaitredSettingsState[category][name]) {
      updatedMaitredSettingsState[category][name].value = event.target.value;
    } else {
      updatedMaitredSettingsState[category][name] = {value: event.target.value};
    }
    let updatedMaitredConfig = this.state.maitred;
    updatedMaitredConfig.styles = stringify(updatedMaitredSettingsState)
    this.setState({
      maitredSettings: updatedMaitredSettingsState,
      maitred: updatedMaitredConfig,
    });
  };

  renderSettingField = (category, key, setting) => {
    let type = maitredSettings[category][key].type;
    if( ! setting) {
      return (
        <Form.Group as={Row} key={key}>
          <Form.Label as={Col}>{maitredSettings[category][key].label}</Form.Label>
          <Form.Control
            onChange={this.handleMaitredChange(category, key)}
            className="col form-control"
            name={key}
            type={type}
            value=""
          />
        </Form.Group>
      );
    } else {
      return (
        <Form.Group as={Row} key={key}>
          <Form.Label as={Col}>{maitredSettings[category][key].label}</Form.Label>
          <Form.Control
            onChange={this.handleMaitredChange(category, key)}
            className="col form-control"
            name={key}
            type={type}
            value={setting.value}
          />
        </Form.Group>
      );
    }
  }

  renderCommunicationField = sms => {
    return (
      <LanguageContext.Consumer>{language => (
        <Form key={sms} onSubmit={e => this.setMaitred(e, sms)}>
          <h5>{language.sms[sms]}</h5>
          <Form.Control
            as="textarea"
            className="col form-control"
            name={sms}
            rows="4"
            onChange={this.handleChange(sms)}
            value={this.state[sms]}
          />
          <Button variant="primary" type="submit" className="update-settings">
            {language.sms[`${sms}_button`]}
          </Button>
        </Form>
      )}</LanguageContext.Consumer>
    );
  }

  handleSelect = key => this.setState({ mockup: key });

  interpolateSMS = (text) => {
    const dummyLink = `${this.state.baseURL}/r3vYgo`;
    const establishment_name = this.state.maitred.establishment_name;
    //return text.replace("{{group_size}}", dummyBooking.group_size);
    return text.replace(/GROUPSIZE|DATE|TIME|NAME|SURNAME|ESTABLISHMENT|LINK/g, function(matched){
      if(matched === "ESTABLISHMENT") return establishment_name;
      if(matched === "LINK") return dummyLink;
      return dummyBooking[matched];
    });
  }

  renderCharcount = sms => {
    const language = this.context;
    const charLimit = 140;
    let charCount = sms.length;
    let charCountClass = (charCount > charLimit) ? "exceeded" : "inlimit";
    let charCountText = (charCount > charLimit)
                        ? language.sms.character_exceded
                          .replace("{{LIMIT}}", charLimit)
                          .replace("{{OVERAGE}}", (charCount - charLimit))
                        : language.sms.character_counter
                          .replace("{{USED}}", charCount)
                          .replace("{{LIMIT}}", charLimit);
    return (
      <div className={`comms-charcount-${charCountClass}`}>
        {charCountText}
      </div>
    );
  }

  mockup = () => {
    if (this.state.mockup === "communication") {
      return (
        <LanguageContext.Consumer>{language => (
        <div className="comms-mockup">
          <label>{language.sms.dummy_booking}</label><br />
          <div className="dummy-booking">
            {Object.keys(dummyBooking).map(i => {
              if(i === "ESTABLISHMENT") dummyBooking[i] = this.state.maitred.establishment_name;
              if(i === "LINK") dummyBooking[i] = `${this.state.baseURL}/r3vYgo`;
              return(<><i>{i}</i> {dummyBooking[i]} <br /></>);
            })}
          </div>
          {Object.keys(communication).map(sms => {
            let interpolatedSMS = this.interpolateSMS(this.state[sms]);
            return (
              <>
              <label>{language.sms[`example_${sms}`]}</label>
              <div className="comms-message">{interpolatedSMS}</div>
              {this.renderCharcount(interpolatedSMS)}
              </>
            );
          })}
        </div>
        )}</LanguageContext.Consumer>
      );
    } else {
      return (
        <MaitredForm
          language={languages["en"]}
          requestSubmitted={() => this.requestSubmitted()}
          setup={this.state.maitred}
          selected_opening_hours={this.state.sittings}
          getSittingsForDate={(date) => this.getSittingsForDate(date)}
          uid={this.state.maitred.uid}
        />);
    }
  }

  handleTablePlanSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData(e.target);
    let formDataArray = [];
    formData.forEach((value, key) => {
      formDataArray.push(value);
    });
    let formDataArrayChunked = []
    while (formDataArray.length) {
        formDataArrayChunked.push(formDataArray.splice(0, 4));
    }
    let tablePlan = formDataArrayChunked.filter(chunk => {
      return !!chunk[0].length;
    }).map(chunk => {
      return {name: chunk[0], capacity: chunk[1], display_order: chunk[2], fill_order: chunk[3]};
    });
    const response = await axios.post("setMaitred",
      {setting: "tables", settingValue: tablePlan},
      {headers: { Authorization: `JWT ${this.accessString}` }}
    );
    if(response.status == 200) {
      this.setState({ isLoading: false });
    } else {
      console.log(JSON.stringify(response));
      this.setState({ isLoading: false });
    }
  }

  tableFormRow = table => {
    return(
      <Form.Group as={Row} key={`table.${table.name}`}>
        <Form.Control
          className="col"
          name="table_name"
          defaultValue={table.name}
          placeholder="Table Name"
        />
        <Form.Control
          className="col"
          name="table_capacity"
          defaultValue="2"
          type="number"
          placeholder="Capacity"
        />
        <Form.Control
          className="col"
          name="table_display_order"
          value={table.display_order}
          type="number"
          placeholder="Capacity"
        />
        <Form.Control
          className="col"
          name="table_fill_order"
          value={table.fill_order}
          type="number"
          placeholder="Capacity"
        />
        <Col>
          <Button variant="danger" onClick={() => this.removeTable(table.name)}>Delete</Button>
        </Col>
      </Form.Group>
    );
  }

  removeTable = name => {
    let updatedTablesList = this.state.tables;
    let updatedListFiltered = updatedTablesList.filter(table => {
      return (table.name === name) ? false : true;
    });
    console.log(updatedListFiltered);
    this.setState({ tables: updatedListFiltered });
  }

  addTable = () => {
    let newTable = {
      name: null,
      capacity: 2,
      display_order: this.state.tables.length + 1,
      fill_order: this.state.tables.length + 1,
    }
    let updatedTablesList = this.state.tables;
    updatedTablesList.push(newTable);
    this.setState({ tables: updatedTablesList });
  }

  toggleTableManagement = async () => {
    await this.setState({ table_management: ! this.state.table_management });
    this.setMaitred(false, "table_management");
  }

  render() {
    const language = this.context;
    if(this.state.isLoading) return <div className="loading"><BeatLoader size={50} /></div>;
    if(this.state.viewportWidth < 1000) return (
      <div className="center-page-wrapper">
        <div className="center-page">
          {language.settings.page_size_limit}
        </div>
      </div>
    );
    return (
      <div className="settings-page">
        <Row>
          <Col md={2}><h3>{language.settings.opening_hours}</h3></Col>
          <Col >
            <Tabs className="nav-fill">
              <Tab eventKey="normal" title={language.settings.regular_opening_hours}>
                {this.renderWeekdayOpeningHours()}
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={2}><h3>{language.settings.maitred_form}</h3></Col>
          <Col>
            <p>
              {language.settings.booking_link_web}:{` `}
              <a target="_blank" href={`${this.state.baseURL}/web/re`}>
                {`${this.state.baseURL}/web/re`}
              </a>
            </p>
            <p>
              {language.settings.booking_link_facebook}:{` `}
              <a target="_blank" href={`${this.state.baseURL}/fb/re`}>
                {`${this.state.baseURL}/fb/re`}
              </a>
            </p>
            <p>
              {language.settings.booking_link_instagram}:{` `}
              <a target="_blank" href={`${this.state.baseURL}/insta/re`}>
                {`${this.state.baseURL}/insta/re`}
              </a>
            </p>
            <Form className="maitred-form-setup" onSubmit={e => this.setMaitred(e, "form_styles")}>
              <Tabs className="nav-fill" onSelect={(key) => this.handleSelect(key)}>
                <Tab eventKey="communication" title="SMS">
                  {Object.keys(communication).map(setting =>
                    this.renderCommunicationField(setting))
                  }
                </Tab>
                <Tab eventKey="general" title="General">
                  {Object.keys(maitredSettings.general).map((i, setting) =>
                    this.renderSettingField("general", i, this.state.maitredSettings.general[i]))
                  }
                </Tab>
                <Tab eventKey="buttons" title="Inputs">
                  {Object.keys(maitredSettings.buttons).map((i, setting) =>
                    this.renderSettingField("buttons", i, this.state.maitredSettings.buttons[i]))
                  }
                </Tab>
              </Tabs>
              <Form.Row>
                <Button variant="primary" type="submit" className="update-settings">
                  {language.settings.update}
                </Button>
              </Form.Row>
            </Form>
          </Col>
          <Col>
            <div className="mock-handheld-device">
              {this.mockup(this.state.mockup)}
            </div>
          </Col>
        </Row>

        <hr />

        {/* {this.state.table_management && */}
        <Row>
          <Col md={2}><h3>Tables</h3></Col>
          <Col>
            <Form className="maitred-table-plan" onSubmit={(e) => this.handleTablePlanSubmit(e)}>
              <Row>
                <Col>Name</Col>
                <Col>Capacity</Col>
                <Col>Display Order</Col>
                <Col>Fill Order</Col>
                <Col></Col>
              </Row>
              {this.state.tables.map(table => {
                return (this.tableFormRow(table));
              })}
              <Button onClick={() => this.addTable()}>Add Table</Button>
              <Button type="submit">Update Table Plan</Button>
            </Form>
          </Col>
        </Row>
        {/* } */}
      </div>
    );
  }
}
Settings.contextType = LanguageContext;
