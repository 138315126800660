import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { Container, Row, Col } from "react-bootstrap";

import background from "assets/img/arches.png"; // transparenttextures.com
import checkedbox from "assets/img/checkedbox.png";

const divStyle = {
  backgroundImage: 'url(' + background + ')',
};

const langs = {
  en: {
    title: "What's the buzz?",
    youtube: "jhkoHtH0HLU",
    bluf: "This is a unique time to be reopening. Resbee connects you directly with your customers, providing the complete flexibility your business needs to start managing booking requests again. With Resbee you also",
    bullets: [
      "Put your brand first with completely customizable booking forms that support every major language",
      "Exclusively own the customer data you collect — Resbee do not use it or sell it on",
      "Get going in minutes — Resbee is fast to configure, straightforward to use and leaves you in complete control",
      "Pay nothing until you are confirming bookings again — there is a small markup per customer communication & booking confirmation SMS message but the app itself is free",
    ],
    learn_more: "Learn more",
    try_resbee: "Try out Resbee",
  },
  es: {
    title: "¿Qué es Resbee?",
    youtube: "BOd3Gj4BroE",
    bluf: "Este es un momento único para vuestra reapertura.  Resbee te conecta directamente con tus clientes, proporcionando la completa flexibilidad que tu negocio necesita para empezar a gestionar las solicitudes de reserva. Con Resbee también",
    bullets: [
      "Destaca tu marca con formularios de reserva completamente personalizables y en cualquier idioma",
      "Los datos de los clientes que recoges son tuyos exclusivamente. Resbee no los utiliza ni los vende",
      "Se pone en marcha en minutos - Resbee es rápido de configurar, fácil de usar y te deja en completo control",
      "No pagas nada hasta que empieces a recibir las peticiones de reservas de tus clientes. Y es sólo un pequeño recargo por cada mensaje SMS de comunicación y confirmación de reserva, pero la aplicación es gratuita",
    ],
    learn_more: "Saber más",
    try_resbee: "Prueba Resbee",
  }
}

const langOptions = [
  { value: 'en', label: '🇬🇧 English' },
  { value: 'es', label: '🇪🇸 Español' },
];

export default class Buzz extends Component {
  constructor(props) {
    super(props);
    this.state = { language: "en" }
  }

  componentDidMount(){
    this.getGeoInfo();
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let countryCode = response.data.country_code;
      if(countryCode === "ES") {
        this.setState({ language: "es" });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  handleChange = selectedLanguage => {
    this.setState({ language: selectedLanguage.value });
  };

  render() {
    let buzz = langs[this.state.language] ? langs[this.state.language] : langs["en"];
    return (
      <div className="buzz-container" style={divStyle}>

        <div className="ext-language-switch">
          <Select
            value={this.state.language}
            onChange={(selectedLanguage) => this.handleChange(selectedLanguage)}
            options={langOptions}
            placeholder="🌎 Language"
          />
        </div>

        <div className="buzz-header">
          {buzz.title}
        </div>
        <Container className="buzz-body">

        <div className="video-responsive">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${buzz.youtube}`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
          <br />
          {buzz.bluf}
          <div>
            {buzz.bullets.map(bullet => {
              return(
                <Row>
                  <Col><img src={checkedbox} /></Col>
                  <Col>
                    {bullet}
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className="button-container">
            <a className="btn btn-primary btn-lg" href="/home#contact">
              {buzz.learn_more}
            </a>
            <a className="btn btn-primary btn-lg" href="/home#register">
              {buzz.try_resbee}
            </a>
          </div>
        </Container>

        <div className="buzz-footer">
          {this.state.language === "en" && "Resbee is a product of Sparviero Ltd | Company Number 12583022"}
        </div>
      </div>
    );
  }
};
