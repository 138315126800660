import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/App.css";

import * as serviceWorker from "serviceWorker";

import Maitred from "views/Maitred";
import Buzz from "layouts/Buzz";
import External from "layouts/External";
import Dashboard from "layouts/Dashboard";

// axios defaults
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';


// presumably passportjs' login switches External-Client here
// React.StrictMode > console highlights potential problems in dev build
// User BaseName=CLIENTID for logged in clients
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/buzz" component={Buzz} />
        <Route path="/home" component={External} />
        <Route path="/:uid/:origin/re" component={Maitred} />
        <Route path="/:uid/:origin/:hash" component={Maitred} />
        <Route path="/dashboard" component={Dashboard} />
        <Redirect from="/" to="/home" />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
