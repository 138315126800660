import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import background from "assets/img/unsplash2.jpg";
import Login from "views/Login";
import Register from "views/Register";
import Contact from "views/Contact";
import languages from "languages/app";

// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const published_key = process.env.REACT_APP_STRIPE_PUBLISHED_KEY;
const stripePromise = loadStripe(published_key);

const divStyle = {
  backgroundImage: 'url(' + background + ')',
};

class External extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: "GB",
      language: "en",
      email_address: null,
      password: null,
      registrationFormDisabled: false,
      anchor: this.props.location.hash ? this.props.location.hash : "#login",
    };
  };

  componentDidMount(){
    this.getGeoInfo();
  }

  getGeoInfo() {
    axios.get('https://ipapi.co/json/').then((response) => {
      let countryCode = response.data.country_code;
      if(countryCode === "ES") {
        this.setState({ language: "es", region: "ES" });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  redirectLogin(email_address, password) {
    this.setState({email_address: email_address, password: password});
  }

  changeLanguage(languageOption) {
    this.setState({ language: languageOption.value });
  };

  render() {
    let language = languages[this.state.language].external
                  ? languages[this.state.language].external
                  : languages["en"].external;
    return (
      <Elements stripe={stripePromise}>
      <div className="ext-language-switch">
        <Select
          value={this.state.language}
          onChange={(selectedLanguage) => this.changeLanguage(selectedLanguage)}
          options={languages.options}
          placeholder="🌎 Language"
        />
      </div>
      <div className="external-wrapper" style={divStyle}>
        <div className="external-box">
          <div className="resbee-title noselect">Resbee</div>
          <div className="splash">
          <Tabs defaultActiveKey={this.state.anchor} className="tabbed-box nav-fill">
            <Tab eventKey="#contact" title={language.contact}>
              <Contact
                language={language}
              />
            </Tab>
            <Tab eventKey="#login" title={language.login}>
              <Login
                language={language}
                email_address={this.state.email_address}
                password={this.state.password}
              />
            </Tab>
            <Tab eventKey="#register" title={language.register}>
              <Register
                region={this.state.region}
                language={language}
                redirectLogin={(e,p) => this.redirectLogin(e,p)}
              />
            </Tab>
          </Tabs>
          </div>
        </div>
      </div>
      </Elements>
    );
  }
};

export default External;
