import React from 'react';
import { Modal } from "react-bootstrap";
import legalText from "languages/legalText";

export default function Legal(props) {
  const privacyJSX = () => {
    return (
      <>
      <Modal.Header closeButton>
        {props.establishment}'s {legalText[props.language].privacy}
      </Modal.Header>
      <Modal.Body>
      <p>We are committed to protecting and respecting your privacy. This policy applies to all our customers who make reservations at our restaurant/s either on the phone, in person, or using an online booking form which is available on our website. This policy sets out the basis on which any personal information we collect from you, either directly or through one of our affiliates, including any third-party data processors, will be handled by us.</p>
<p>Please read the following carefully to understand our views and practices regarding your personal information and how we will treat it.</p>
<p>For the purposes of the Data Protection Act 1998, we are the data controller in respect of all personal data we collect about you. Our registered address is available on our website.</p>
<p><strong>DATA PROCESSING</strong><br />We may outsource some of our data processing activities to a third party data processor. We are responsible for the security of your personal information and assure you that adequate security measures are in place to keep private and secure all information collected about you.</p>
<p><strong>THE INFORMATION WE COLLECT</strong><br />We may collect the following types of information about you:</p>
<ol>
<li>Contact information, including your name, address, e-mail address, or phone number.</li>
<li>Payment information, only to confirm booking reservations on certain dates and special occasions when our restaurant/s will be fully booked. We will not collect or process any payment information ourselves, but will use a reputable third party payment processor.</li>
<li>Information about your previous visits to our restaurant/s, including products or services used, promotions, booking size, duration or purpose.</li>
<li>Technical information about your use of our website or the website of our data processor.</li>
<li>Previous correspondence, including feedback or comments.</li>
</ol>
<p><strong>HOW WE USE YOUR INFORMATION</strong><br />We may use your information for the following purposes:</p>
<ol>
<li>To make or amend reservations at our restaurant/s.</li>
<li>To update our records.</li>
<li>To improve our website or services, including those of our data processor.</li>
<li>To inform you of any products, services or special offers at our restaurant or group which are similar to products or services you have previously enjoyed or expressed an interest in.</li>
</ol>
<p>We may share your information with others in the following situations:</p>
<ol>
<li>We may regularly share your information with our data processor.</li>
<li>If we buy or sell any business or assets, in which case we may disclose your information to the buyer or seller or such business or assets.</li>
<li>If we are under a duty to disclose your personal data to comply with any legal obligation or in order to enforce or apply our terms and conditions and other agreements, or to protect the rights, property, or safety of our customers, or others. This includes exchanging information with other companies and organisations for the purpose of fraud protection.</li>
</ol>
<p><strong>PROMOTIONS AND MARKETING</strong><br />We would like to contact you using the information you have provided in order to let you know about some of our new products or services, any promotional offers or discounts we would like to make available to you, or any improvements or changes we make to our restaurant/s, products or services. You will be given the option to opt out of receiving any such communications, both when we collect your information (by checking certain boxes on the booking form), and on each occasion that we send you information advertising our products or services. You may also opt out by contacting us directly using the contact information on our website.</p>
<p><strong>ACCESS TO INFORMATION</strong><br />You may request access to the information that we hold about you by contacting us directly, using the contact information available on our website. We may charge a reasonable fee for any access request.</p>
<p><strong>CONSENT</strong><br />By making or attempting to make a booking reservation at our restaurant/s, contacting us or otherwise providing your personal information to either us our data processor, you consent to our collection and processing of your information as described in this Privacy Policy. We will update this Privacy Policy in the event that we change any of the practices involving your personal information.<br /><br /></p>
      </Modal.Body>
      </>
    );
  }

  const termsJSX = () => {
    return (
      <>
      <Modal.Header closeButton>
        Resbee {legalText[props.language].terms}
      </Modal.Header>
      <Modal.Body>
      <p class="p1"><span class="s1"><strong>Resbee Restaurant Reservation Service</strong></span></p>
      <p class="p3">Resbee is a restaurant reservation and management cloud-based Service, provided by Sparviero Limited (&ldquo;Resbee&rdquo;) to restaurants and commercial clients (&ldquo;Users&rdquo;). More details of the Service can be found at <a href="https://resbee.app"><span class="s1">https://resbee.app</span></a>.</p>
      <p class="p3">These terms and conditions (&ldquo;The Agreement&rdquo;) apply to the customers and consumer clients (&ldquo;You&rdquo;) of the restaurants or other commercial client users (&ldquo;Users&rdquo;) of the Resbee reservation service (&ldquo;The Service&rdquo;).</p>
      <p class="p3">When you attempt to make a booking reservation using the Resbee restaurant reservation Service, by submitting a booking request, for example when you click the &ldquo;Make Booking&rdquo; button on one of Resbee&rsquo;s online booking forms, or by clicking a box indicating that you agree to these terms and conditions, you</p>
      <ul class="ul1">
      <li class="li3">Are agreeing to the terms and conditions contained in this Agreement in their entirety, and in unmodified form, as contained hereunder.</li>
      <li class="li3">Are agreeing that you have read, understand and consent to the practices described in our&nbsp;Privacy Policy</li>
      <li class="li3">Represent and warrant that you have legal capacity to enter into this Agreement and that you have read fully all the terms of this Agreement, are capable of understanding those terms, and do understand them.</li>
      <li class="li3">Acknowledge that Resbee may update this Agreement from time to time, and without notification to you. You warrant that you have read&nbsp;the latest version of the agreement, and agree to all the terms contained within it. The version of this Agreement which is available at the time you make the booking is the version which shall have effect.</li>
      </ul>
      <p class="p3">This Agreement (together with the Client Customer&nbsp;Privacy Policy) and any other documents referred to in it) sets out the whole of the Agreement between you and Resbee to the exclusion of any other terms that you, Resbee or the Users seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Your use of the Service</strong></span></p>
      <p class="p3">You may make booking reservations at restaurant and commercial client Users of the Service using Resbee&rsquo;s cloud-based online Service, either directly via www.Resbee.io, or when you are redirected to Resbee&rsquo;s online booking form from a User&rsquo;s website. When you submit a booking request, you will be provided with a unique Reservation Number which you should retain as confirmation of your booking request, and in the event that you should need to cancel or amend your booking reservation. The restaurant or commercial client User will then allocate your seating according to their availability and may send you an email confirming your reservation. These usage restrictions apply to you whichever means by which you arrived at a Resbee online booking form.</p>
      <p class="p3">When you use the Service to make a booking reservation at a restaurant or commercial client User that you wish to make the booking at (the &ldquo;Relevant User&rdquo;), you represent and warrant that you:</p>
      <ul class="ul1">
      <li class="li3">Will provide accurate personal information when submitting a booking request;</li>
      <li class="li3">Will not make booking reservations which you know or reasonably suspect that you will be unable to attend;</li>
      <li class="li3">Understand that submitting a booking request does not automatically guarantee your booking, and that your booking will not be confirmed until such time as the Relevant User sends you a booking confirmation email - and that any booking requests are subject to the Relevant User&rsquo;s ability to seat you.</li>
      <li class="li3">Will honour your booking reservation by arriving on time to the Relevant User&rsquo;s venue, and will make use on that occasion of the Relevant User&rsquo;s commercial service/s (by ordering and paying for food, drink, or other appropriate use of the Relevant User&rsquo;s business venue).</li>
      <li class="li3">Will not be able to cancel or amend your booking reservation less than the number of hours or minutes set by the relevant User before the scheduled time for the reservation, and in the event of cancellation or amendment, you must cancel or amend either via Resbee&rsquo;s online Service using your Reservation Number or by contacting the Relevant User directly by telephone.</li>
      <li class="li3">Understand and accept that your access to the Service may be blocked for repeated failure to turn up to your booking reservations, or for any other serious, extended or repeated breaches of this Agreement.</li>
      <li class="li3">gree that you will comply with all applicable laws and regulations with respect to your activities under this Agreement.</li>
      </ul>
      <p class="p3">Except as may be allowed by any applicable law which is incapable of exclusion by agreement, and except to the extent expressly permitted under this Agreement, you agree that you shall not:</p>
      <ul class="ul1">
      <li class="li3">Attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display in public (save and except to the extent necessary for access to and use of the Service), transmit, or distribute all or any portion of the Service in any form or media or by any means; or attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Service; or access all or any part of the Service in order to build a product or service which competes with the Service; or</li>
      <li class="li3">Use the Service to provide services to third parties; or</li>
      <li class="li3">License, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Service available to any third party; or</li>
      <li class="li3">Attempt to obtain, or assist third parties in obtaining, access to the Service.</li>
      <li class="li3">Access, store, distribute or transmit any viruses, or any material during the course of its use of the Service that is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive, facilitates illegal activity, depicts material of a sexual nature, promotes unlawful violence, is discriminatory on the basis of any protected characteristic under the law of England and Wales, or is otherwise illegal or causes damage or injury to any person or property of any form whatsoever.</li>
      </ul>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Technical Requirements and Disclaimer</strong></span></p>
      <p class="p3">The Resbee Service is accessible to you as an online-only cloud-based service. You acknowledge that you will be able to access the Service to make, amend or cancel booking reservations via compatible computers and mobile devices only. Your device will need to be capable of reading JavaScript, and must have JavaScript enabled. Resbee provides no guarantee whatsoever that your device will be compatible with the Service. You shall be solely responsible for ensuring that you access the Service via a compatible device for the purpose of making, amending or cancelling a booking reservation, or for any other related purpose under this Agreement. Failure to access the Service via a compatible device shall not exempt you from any of your obligations under this Agreement. You acknowledge and agree that Resbee will not:</p>
      <ul class="ul1">
      <li class="li3">Warrant that your use of the Service will be uninterrupted or error-free; or that the Service, and/or the information obtained by you through the Service will be accurate.</li>
      <li class="li3">Be held responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and you acknowledge that the Service may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</li>
      </ul>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Personal Information, Data, and Intellectual Property</strong></span></p>
      <p class="p3">You will be required to provide personal information in order to make a booking reservation through the Service. You acknowledge that you will be providing this information to Resbee exclusively for and on behalf of the Relevant User, including when you are redirected to one of Resbee&rsquo;s online booking forms from the Relevant User&rsquo;s public website. Resbee will then share this information with the Relevant User for the purpose of processing and confirming your booking reservation. You acknowledge and accept that it is the Relevant User, and not Resbee, that shall have control over how your information will be used.</p>
      <p class="p3">In doing so, you acknowledge that the Relevant User shall own all right, title and interest in and to all of the information uploaded by you, to the maximum extent which is compatible with any applicable law. You accept that you shall have responsibility for the legality, reliability, integrity, accuracy and quality of any information uploaded by you.</p>
      <p class="p3">You acknowledge and agree that Resbee owns all intellectual property rights in the Resbee Service. Except as expressly stated herein, this Agreement does not grant to you any rights to, or in, patents, copyright, database right, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of any part of the Service.</p>
      <p class="p3">You acknowledge and agree that your personal data may be transferred or stored outside the EEA or the country where the User is located in order to carry out the Service and Resbee&rsquo;s other obligations.</p>
      <p class="p3">You warrant that you are entitled to transfer the personal data you provide to Resbee so that Resbee may lawfully process the personal data in accordance with this Agreement on behalf of the Relevant User.</p>
      <p class="p3">Further information about how we are authorised and instructed to process your personal information on behalf of the Relevant User can be found in our&nbsp;Privacy Policy. For more information about how the Relevant User will handle your information, please refer to the Relevant User&rsquo;s own privacy policy, which can be found on the Relevant User&rsquo;s website.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Payment Information</strong></span></p>
      <p class="p3">On some occasions, it may be necessary to provide credit/debit card or other payment information in order to confirm bookings reservations on particular dates in particular venues. Where this is necessary, you will be asked to enter your payment information, and associated billing information for the purpose of authentication. Please refer to our&nbsp;Privacy Policy for further details as to how this information will be processed.</p>
      <p class="p3">Where you provide payment information, you represent and warrant that:</p>
      <ul class="ul1">
      <li class="li3">The information you provide is accurate and complete.</li>
      <li class="li3">The information you provide relates to a credit/debit card or bank account that is in your name, or is a credit/debit card or bank account for which you are legally authorised to provide such information to Resbee.</li>
      <li class="li3">That credit/debit card or bank account is active at the time you enter the information and will remain active on the date of the booking reservation that the payment relates to.</li>
      <li class="li3">You are not prohibited from and legally authorised to make payments from that credit/debit card or bank account.</li>
      <li class="li3">There are sufficient funds or credit available on that credit/debit card or in that bank account to complete the payment.</li>
      <li class="li3">Such action does not violate the applicable terms and conditions of use of that credit/ debit card or bank account.</li>
      <li class="li3">You acknowledge that you shall be solely liable for any collateral charges or fees accrued through your use of that credit/debit card or bank account.</li>
      <li class="li3">You acknowledge that Resbee shall have no liability whatsoever for any disputes arising out of the use of a third party payment processor, and you accept that any information you provide to such third party payment processor is given at your own risk.</li>
      </ul>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Maintenance &amp; Technical Support</strong></span></p>
      <p class="p3">Resbee uses commercially reasonable endeavours to make the Service available 24 hours a day, seven days a week, except for occasional planned maintenance carried out during the maintenance window of between 1am and 6am (Relevant User&rsquo;s local time), and unscheduled maintenance, where necessary. Where unscheduled maintenance inhibits access to the Service, you will be required to inform the Relevant User directly if you wish to amend or cancel your booking reservation.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Limitation of Liability</strong></span></p>
      <p class="p3">Resbee shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss, personal injury, inconveniences, loss or corruption of data or information (including by hacking), or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this Agreement.</p>
      <p class="p3">Resbee shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided to Resbee by the User in connection with the Service, or any actions taken by Resbee at the User&rsquo;s direction.</p>
      <p class="p3">The Service is provided to you on an &lsquo;as is&rsquo; basis. Except as provided in this Agreement, all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this Agreement.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Indemnity</strong></span></p>
      <p class="p3">You agree that you shall indemnify, hold harmless and defend Resbee, its affiliated Users, their employees and agents from and against any claims resulting from any information provided by you, or from any breach of this Agreement.</p>
      <p class="p3">You acknowledge that the restaurant and commercial client Users are responsible for all results obtained through their use of the Service. In the event of any dispute arising from the User&rsquo;s use of the Service, or your reliance thereupon, your sole recourse shall be against the Relevant User itself.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Entire Agreement</strong></span></p>
      <p class="p3">This agreement, and any documents referred to in it, constitute the whole agreement between the parties and supersede any previous arrangement, understanding or agreement between them relating to the subject matter they cover.</p>
      <p class="p3">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Severance</strong></span></p>
      <p class="p3">If any provision (or part of a provision) of this agreement is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.</p>
      <p class="p3">If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever minimum modification is necessary to give effect to the commercial intention of the parties.</p>
      <p class="p3">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Assignment, Variation &amp; Waiver</strong></span></p>
      <p class="p3">You agree that you shall not assign, transfer, charge, sub-contract or deal in any other manner with all or any of your rights or obligations under this Agreement.</p>
      <p class="p3">Resbee may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this Agreement.</p>
      <p class="p3">Resbee reserves the right to update or vary this Agreement from time to time. You will be asked to agree to the current version of this Agreement each time you submit a booking request. The version of this Agreement which is available at the time you submit your booking request is the version which shall have effect.</p>
      <p class="p3">No failure or delay by a party to exercise any right or remedy provided under this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy.</p>
      <p class="p2">&nbsp;</p>
      <p class="p1"><span class="s1"><strong>Governing Law and Jurisdiction</strong></span></p>
      <p class="p3">This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales. Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
      </Modal.Body>
      </>
    );
  }

  return props.type === "privacy" ? privacyJSX() : termsJSX();
}
