// [public facing] contact us
import React from 'react';
import { Form, Button, Col } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import MobilePhone from 'components/MobilePhone'
import axios from 'axios';
import ParticleEffectButton from 'react-particle-effect-button';

export default function Contact(props) {
  /* "handleSubmit" will validate inputs before invoking "onSubmit" */
  const { register, handleSubmit, watch, errors } = useForm();

  const [phone_number, setPhoneNumber] = React.useState();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const onSubmit = async (data) => {
    setIsSubmitted(true);
    data["phone_number"] = `+${phone_number}`;
    try {
      await axios.post('contactResbee', data);
    } catch (error) {
      setIsSubmitted(false);
      return;
    }
  }

  const formFields = () => {
    return (<div>
    <Form.Group>
      <Form.Label>{props.language.your_name}</Form.Label>
      <Form.Control
        name="name"
        ref={register({ required: true })}
        placeholder={props.language.name}
        className={(errors.name) ? "error" : ""}
      />
    </Form.Group>

    <Form.Row><Form.Label>{props.language.email} / {props.language.phone}</Form.Label></Form.Row>

    <Form.Row>
    <Form.Group as={Col}>
      <Form.Control
        name="email_address"
        type="email"
        ref={register({ required: true })}
        placeholder={props.language.email}
        className={(errors.email_address) ? "error" : ""}
      />
    </Form.Group>
    <vr as={Col}>&nbsp;</vr>
    <Form.Group as={Col}>
      <MobilePhone setPhoneNumber={setPhoneNumber} placeholderText={props.language.phone} />
    </Form.Group>
    </Form.Row>

    <Form.Group>
      <Form.Label>{props.language.message}</Form.Label>
      <Form.Control as="textarea" ref={register()} rows="3" name="message" />
    </Form.Group>
    </div>);
  }

  const thankYou = () => {
    return (<div className="big-script">Thank you</div>);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {(isSubmitted) ? thankYou() : formFields()}
      <Form.Row>
      <ParticleEffectButton color='#007bff' hidden={isSubmitted}>
        <Button type="submit" size="lg">
          {props.language.get_started}
        </Button>
      </ParticleEffectButton>
      </Form.Row>

    </Form>
  );
}
