// Account page, payments profile and login settings

import React, { Component } from "react";
import { Button, Row, Col, Form, Card, ListGroup } from "react-bootstrap";
import Register from "views/Register";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import bee from "assets/img/bee.png";
import LanguageContext from "LanguageContext";

// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import 'assets/css/Stripe.css'
const published_key = "pk_test_tb3tzsMykt79k0X7pKczb1Sk00aDbz3yYd";
const stripePromise = loadStripe(published_key);

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_password: null,
      new_password: null,
      new_password_repeated: null,
      isLoading: true,
      changingPassword: false,
      connectingStripe: false,
      profile: {},
      sms_count: 0
    };
    this.accessString = localStorage.getItem('JWT');
  };

  async componentDidMount() {
    const response = await axios.get("getClientSettings", {
      headers: { Authorization: `JWT ${this.accessString}` },
    });
    const stripeAccount = await axios.get("getStripeAccount", {
      headers: { Authorization: `JWT ${this.accessString}` },
    }).catch(error => console.warn(error));
    
    this.setState({
      stripeAccount: stripeAccount ? stripeAccount.data : false,
      isLoading: false,
      profile: response.data.profile,
      smsCount: response.data.sms_count,
    });
  }

  async connectStripe() {
    this.setState({connectingStripe: true})
    const response = await axios.get("getStripeConnect", {
      headers: { Authorization: `JWT ${this.accessString}` },
    });
    if(response.status === 200) window.location = response.data;
  }

  showChangePasswordForm() {
    this.setState({changingPassword: true});
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  changePasswordForm() {
    return(
      <LanguageContext.Consumer>{language => (
        <Form className="reset-form" onSubmit={this.changePassword}>
          <Form.Group>
            <Form.Label>{language.account.current_password}</Form.Label>
            <Form.Control type="password" onChange={this.handleChange('current_password')} />
            <Form.Label>{language.account.new_password}</Form.Label>
            <Form.Control type="password" onChange={this.handleChange('new_password')} />
            <Form.Label>{language.account.repeat_password}</Form.Label>
            <Form.Control type="password" onChange={this.handleChange('new_password_repeated')} />
          </Form.Group>
          <Button variant="danger" type="submit">
            {language.account.change_password}
          </Button>
        </Form>
      )}</LanguageContext.Consumer>
    );
  }

  changePassword = async (e) => {
    e.preventDefault()
    const { current_password, new_password, new_password_repeated } = this.state;

    this.setState({isLoading: true});

    if(new_password === new_password_repeated) {
      const response = await axios.post("changeClientPassword",
        {
          email_address: this.state.profile.email_address,
          current_password: current_password,
          new_password: new_password
        },
        { headers: { Authorization: `JWT ${this.accessString}` } }
      );

      if(response.status == 200) {
        this.setState({
          isLoading: false,
          changingPassword: false,
        });
      } else {
        this.setState({
          isLoading: false,
          changingPassword: false,
        });
      }
    } else {
      alert("Passwords do not match!");
    }
  }

  renderBee = tier => {
    if(tier === 3 && this.state.smsCount > 90) {
      return <img alt="bee" src={bee} className="tier-bee" />
    }
    else if(tier === 2 && (this.state.smsCount > 60 && this.state.smsCount <= 90)) {
      return <img alt="bee" src={bee} className="tier-bee" />
    }
    else if(tier === 1 && (this.state.smsCount > 30 && this.state.smsCount <= 60)) {
      return <img alt="bee" src={bee} className="tier-bee" />
    }
    else if(tier === 0 && this.state.smsCount <= 30) {
      return <img alt="bee" src={bee} className="tier-bee" />
    }
  }

  renderPriceTier = tier => {
    return (
      <LanguageContext.Consumer key={`key-${tier}`}>{language => (
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>{this.renderBee(tier)} {language.account.tier_pricing[tier].title}</ListGroup.Item>
            <ListGroup.Item>{language.account.tier_pricing[tier].price}</ListGroup.Item>
            <ListGroup.Item>{language.account.per_sms}</ListGroup.Item>
          </ListGroup>
        </Card>
      )}</LanguageContext.Consumer>
    );
  }

  renderStripeAccount(language) {
    if(this.state.connectingStripe) {
      return <BeatLoader size={30} />;
    }
    else if(this.state.stripeAccount) {
      return (
        <a className="stripe-account-connected" href="https://www.stripe.com/login" target="_blank">
          {language.account.stripe_connected_description} {this.state.stripeAccount.email}
        </a>
      );
    }
    else {
      return (
        <>
          {language.account.stripe_description}
          <a href="#" className="stripe-connect" onClick={() => this.connectStripe()}><span>Connect with</span></a>
        </>
      );
    }
  }

  render() {
    if(this.state.isLoading) return <div className="loading"><BeatLoader size={50} /></div>;
    return (
      <LanguageContext.Consumer>{language => (
      <Elements stripe={stripePromise}>
        <div className="settings-page">
        <Row>
          <Col md={2}><h3>{language.account.details}</h3></Col>
          <Col>
            <Register
              language={language.external}
              disabled={true}
              isclient={true}
              formdata={this.state.profile}
            />
            </Col>
        </Row>

        <hr />

        <Row>
          <Col md={2}><h3>{language.account.stripe}</h3></Col>
          <Col>
            {this.renderStripeAccount(language)}
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={2}><h3>{language.account.sms_usage}</h3></Col>
          <Col className="align-center">
            <i className="SMS-infobox">
              {language.account.sms_usage_explanation
                .replace("{{ESTABLISHMENT}}", this.state.profile.establishment)
                .replace("{{MESSAGE_COUNT}}", this.state.smsCount)
              }
            </i>
            <br />
            {[0,1,2,3].map(tier => this.renderPriceTier(tier))}
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={2}><h3>{language.account.security}</h3></Col>
          <Col>
          {(this.state.changingPassword) ?
            this.changePasswordForm()
            :
            <Button onClick={() => this.showChangePasswordForm()} varient="primary">
              {language.account.change_password}
            </Button>
          }
          </Col>
        </Row>

        {/*
        <hr />
        <Row>
          <Col md={2}><h3>Payments</h3></Col>
          <Col><Button varient="primary">Update Payment Method</Button></Col>
        </Row>
        */}
        </div>
      </Elements>
    )}</LanguageContext.Consumer>
    );
  }
}

export default Account;
