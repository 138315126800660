import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import 'moment/locale/es';

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import axios from "axios";

import NewBooking from "views/NewBooking";
import Requests from "views/Requests";
import Spacetime from "views/Spacetime";
import Bookings from "views/Bookings";
import Customers from "views/Customers";
import Analytics from "views/Analytics";
import Settings from "views/Settings";
import Account from "views/Account";

import languages from "languages/app";
import LanguageContext from "LanguageContext";
import dashboardRoutes from "routes";

import phoneRinging from "assets/sound/phone-ring.mp3";
import serviceBell from "assets/sound/service-bell.mp3";

import io from 'socket.io-client';

const socket = io.connect(process.env.REACT_APP_SOCKET, {
   transports: ['websocket'],
   secure: true
});

let audio = new Audio(serviceBell);
let audioL = new Audio(phoneRinging);
audioL.loop = true;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: this.props.location.hash ? this.props.location.hash.substr(1) : "overview",
      language: "en",
      isLoading: true,
      isLoggedIn: false,
      spacetimeView: false,
      bookingRequests: false,
      newAutoconfirmedBooking: false,
    };
  };

  // Lets make sure we are logged in, or we show the login page
  async componentDidMount() {
    const accessString = localStorage.getItem('JWT');

    if (accessString == null) {
      console.log("no token!");
      this.setState({
        isLoading: false,
        isLoggedIn: false,
      });
    } else {

      try {
        const bookingRequests = await axios.get("getClientRequests", {
          headers: { Authorization: `JWT ${accessString}` },
        });

        this.setState({bookingRequests: bookingRequests.data.length});

        const response = await axios.get('getClientSettings', {
          headers: { Authorization: `JWT ${accessString}` },
        });
        if(response.data) {
          socket.on(`bookingRequest_${response.data.profile.uid}`, () => {
            this.setState({bookingRequests: moment().unix()});
          });
          socket.on(`autoConfirm_${response.data.profile.uid}`, () => {
            this.newAutoconfirmedBooking();
          });
          let language = languages[response.data.profile.language] ? response.data.profile.language : "en";
          this.setState({
            isLoggedIn: true,
            isLoading: false,
            language: language,
            spacetimeView: response.data.maitred.table_management,
            error: false,
          });
          moment.locale(language);
        }
        if(bookingRequests.data.length) audioL.play();
      } catch (error) {
        console.log("not logged in with that token!");
        this.setState({
          isLoading: false,
          isLoggedIn: false,
        });
      }
    }
  }

  newAutoconfirmedBooking() {
    this.setState({ newAutoconfirmedBooking: true });
    audio.play();
    setTimeout(() => {
      this.setState({ newAutoconfirmedBooking: false });
    }, 2000);
  }

  translateDashboardRoutes() {
    let language = languages[this.state.language];
    return dashboardRoutes.map(route => {
      route.name = language.navigation[route.id];
      return route;
    });
  }

  filterDashboardRoutes() {
    return this.translateDashboardRoutes().filter(route => {
      if(route.id === "spacetime" && ! this.state.spacetimeView) return false;
      return true;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Prevent "too many nested updates" react error hell
    if(prevState.bookingRequests !== this.state.bookingRequests) {
      (this.state.bookingRequests > 1) ? audioL.play() : audioL.pause();
    }
  }

  subtractBookingRequest(total) {
    if(total < 1) this.setState({bookingRequests: false});
  }

  switchView(componentName) {
    this.setState({ path: componentName });
  }

  showView() {
    switch (this.state.path) {
      case 'new':
        return <NewBooking
                  newAutoconfirmedBooking={() => this.newAutoconfirmedBooking()}
                />
      case 'overview':
        return <Requests
                  bookingRequests={this.state.bookingRequests}
                  minusBookingRequest={(total) => this.subtractBookingRequest(total)}
                />;
      case 'spacetime':
        if(this.state.spacetimeView) return <Spacetime />;
        break;
      case 'bookings':
        return <Bookings />;
      case 'customers':
        return <Customers />;
      case 'analytics':
        return <Analytics />;
      case 'settings':
        return <Settings />;
      case 'account':
        return <Account />;
      default:
        return <Bookings />;
    }
  }

  render() {
    // still determining if logged in or not
    if( this.state.isLoading ) return <div className="loading"><BeatLoader size={50} /></div>;
    // if we are not logged in show the login page
    if ( ! this.state.isLoggedIn) return(<Redirect to={"/home"} />);
    // if logged in show dashboard
    return (
      <LanguageContext.Provider value={languages[this.state.language]}>
        <div className="d-flex" id="wrapper">
          <Sidebar
            routes={this.filterDashboardRoutes()}
            switchView={(componentName) => this.switchView(componentName)}
            bookingRequests={this.state.bookingRequests}
            newAutoconfirmedBooking={this.state.newAutoconfirmedBooking}
          />

          <div id="page-content-wrapper">
            <Header
              routes={this.filterDashboardRoutes()}
              switchView={(componentName) => this.switchView(componentName)}
              bookingRequests={this.state.bookingRequests}
              newAutoconfirmedBooking={this.state.newAutoconfirmedBooking}
            />
            <div className="container-fluid">
              {this.showView()}
            </div>
          </div>
        </div>
      </LanguageContext.Provider>
    );
  }
};

export default Dashboard;
