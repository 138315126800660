import React from 'react';
import { useDrop } from 'react-dnd';

export default function DropZone(props) {
  const moveBooking = () => {
    props.updateBookingTables();
  }

  const canDropBooking = () => {
    return true;
  }

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "booking",
    drop: () => moveBooking(),
    canDrop: () => canDropBooking(),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const style = {
    width: props.width,
    height: `${3 * props.height}em`,
  }

  return (
    <div ref={drop} style={style} className="drop-zone">
      {isOver && ! canDrop && <div className="prohibited"></div>}
      { ! isOver && canDrop && <div className="potential"></div>}
      {isOver && canDrop && <div className="targeted"></div>}
    </div>
  );
}
