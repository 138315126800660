import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Form, Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import MobilePhone from 'components/MobilePhone'
import BeatLoader from "react-spinners/BeatLoader";
import Select from 'react-select';
import axios from 'axios';

import CardSection from 'components/CardSection';

export default function Register(props) {
  const regions = [
    { value: 'GBP', label: '🇬🇧. GBP - £ - United Kingdom' },
    { value: 'EUR', label: '🇪🇺. EUR - € - Eurozone' },
  ];
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, errors } = useForm();

  const setDefaultRegion = currency_code => {
    return regions.filter(region => {
      return region.value === currency_code ? true : false;
    })[0];
  }

/**
 * onSubmit registers new Resbee customer. Registration has four main steps.
 *
 * 1 | STRIPE | Post new customer details to attach to new setupIntent
 * 2 | STRIPE | Using setupIntent, await stripe.confirmCardSetup() for customer
 * 3 | RESBEE | Register customer with Resbee
 * 4 | CHGBEE | chargebee.subscription.create using setupIntent.id as the gw_token
 */
  const onSubmit = async (data) => {
    // Pre-registration
    if( ! isSubmitted) { setIsSubmitted(true); } else { return; }

    data["currency_code"] = (typeof(region) !== "undefined") ? region.value : "GBP";
    data["phone_number"] = phone_number;
    if ( ! stripe || ! elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsSubmitted(false);
      return;
    }

    // Step 1 | Get Stripe setupIntent attached to new customer
    const setupIntent = await axios.post("getStripeSetupIntent", data);

    // Step 2 | Actually confirm card details from Registration form
    const cardSetup = await stripe.confirmCardSetup(setupIntent.data, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${data.first_name} ${data.last_name}`,
        },
      }
    });
    if (cardSetup.error || cardSetup.setupIntent.status !== 'succeeded') {
      // todo: Show error to the potential resbee customer (e.g., insufficient funds)
      console.error(
        "Stripe could not confirm card setup, or payment intent is not in the desired state for some reason",
        cardSetup
      );
      setIsSubmitted(false);
      alert("Please check those card details");
      return;
    }
    console.log("cardSetup",cardSetup)

    // Step 3 | Resbee Registration
    try {
      const response = await axios.post('registerClient', data);
      data.uid = response.data.user;
      console.log("Resbee registration", response);
    } catch (error) {
      alert("Something went wrong in the setup process - please contact us");
      console.error("Resbee registration", error.response);
      setIsSubmitted(false);
      return;
    }

    // Step 4 | Setup client with Chargebee
    data.gw_token = cardSetup.setupIntent.id;
    try {
      const response = await axios.post('createSubscription', data);
      data.uid = response.data.user;
      console.log("Chargebee subscription", response);
    } catch (error) {
      console.error("Chargebee subscription", error.response);
      setIsSubmitted(false);
      return;
    }

    // Post-registration
    return props.redirectLogin(data["email_address"], data["password"]);
  };

  const registrationFields = () => {
    return (
      <div>
      <Form.Group>
        <Form.Label>{props.language.choose_password}</Form.Label>
        <Form.Control
          name="password"
          disabled={props.disabled}
          ref={register({ required: true })}
          type="password"
          placeholder={props.language.password}
          className={(errors.password) ? "error" : ""}
        />
        {(errors.password) && <div className="error-message">{props.language.password_error}</div>}
      </Form.Group>

      <Form.Group>
        <Form.Label>{props.language.credit_debit}</Form.Label>
          <CardSection />
        <Form.Text className="text-muted">
           {props.language.card_subtext}
        </Form.Text>

        <Form.Row>
          {( ! isSubmitted) ?
          <Button disabled={ ! stripe} variant="primary" type="submit" className="btn-register" size="lg">
            {props.language.get_started}
          </Button>
          : <div className="loading inline"><BeatLoader size={25} /></div> }
        </Form.Row>
      </Form.Group>
      </div>
    );
  }

  const modificationFields = () => {
    return "";
    //return (
    //  <Form.Group>
    //    <Button varient="primary" disabled>Edit Details</Button>
    //  </Form.Group>
    //);
  }

  const [phone_number, setPhoneNumber] = React.useState();
  const [region, setRegion] = React.useState();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  return (
    <Form className="register-form" onSubmit={handleSubmit(onSubmit)}>

      <Form.Row>
        <Form.Group as={Col}>
          <Select
            options={regions}
            defaultValue={props.formdata && setDefaultRegion(props.formdata.currency_code)}
            placeholder={props.language.currency_region}
            onChange={setRegion}
            isDisabled={props.isclient}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>{props.language.your_name}</Form.Label>
          <Form.Control
            name="first_name"
            disabled={props.disabled}
            type="text"
            placeholder={props.language.name}
            defaultValue={(props.formdata) ? props.formdata.first_name : ""}
            ref={register({ required: true })}
            className={(errors.first_name) ? "error" : ""}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>&nbsp;</Form.Label>
          <Form.Control
            name="last_name"
            disabled={props.disabled}
            type="text"
            placeholder={props.language.surname}
            defaultValue={(props.formdata) ? props.formdata.last_name : ""}
            ref={register({ required: true })}
            className={(errors.last_name) ? "error" : ""}
          />
          <Form.Control.Feedback>{props.language.good}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Group>
        <Form.Label>{props.language.your_establishment}</Form.Label>
        <Form.Control
          name="establishment"
          type="text"
          disabled={props.disabled}
          defaultValue={(props.formdata) ? props.formdata.establishment : ""}
          placeholder={props.language.your_establishment_placeholder}
          ref={register({ required: true })}
          className={(errors.establishment) ? "error" : ""}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>{props.language.your_email}</Form.Label>
        <Form.Control
          name="email_address"
          type="email"
          disabled={props.disabled}
          placeholder={props.language.your_email_placeholder}
          defaultValue={(props.formdata) ? props.formdata.email_address : ""}
          ref={register({ required: true })}
          className={(errors.email_address) ? "error" : ""}
        />
        <Form.Text className="text-muted">
          {props.language.your_email_subtext}
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>{props.language.your_phone}</Form.Label>
          <MobilePhone setPhoneNumber={setPhoneNumber} placeholderText={props.language.your_phone_placeholder} />
      </Form.Group>

      {(props.isclient) ? modificationFields() : registrationFields()}

    </Form>
  );
}
