import React from 'react';
import { Button } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import PhoneInput from 'react-phone-number-input';
import BarLoader from 'react-spinners/BarLoader';
import LanguageContext from "LanguageContext";
import moment from 'moment';
import web from "assets/img/logo.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import 'react-phone-number-input/style.css';

const filters = {
  "StringColumnFilter": StringColumnFilter,
};

// Define a default UI for filtering
function StringColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <LanguageContext.Consumer>{language => (
      <input
        className="form-control filter-form"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`${language.common.search}...`}
      />
    )}</LanguageContext.Consumer>
  );
}

function Table({ columns, data, rowClick }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const renderOrigin = (name) => {
    if ( ! name) return "";
    if(name === "web")
      return <img className="origin-logo" src={web} />;
    if(name === "facebook")
      return <img className="origin-logo" src={facebook} />;
    if(name === "instagram")
      return <img className="origin-logo" src={instagram} />;
  }

  let csvLink;
  let exportableData = data.map(row => {
    let exportableRow = {};
    columns.map(column => {
      if(column.type === "calendar") {
        exportableRow[column.Header]
          = moment.unix(row[column.accessor]).format("MMMM Do YYYY - HH:mm");
      }
      else {
        exportableRow[column.Header] = row[column.accessor];
      }
    });
    return exportableRow;
  });


  const pagination = (<div className="pagination">
    <Button className="super-stubby-input" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {'<<'}
    </Button>{' '}
    <Button className="super-stubby-input" onClick={() => previousPage()} disabled={!canPreviousPage}>
      {'<'}
    </Button>{' '}
    <Button className="super-stubby-input" onClick={() => nextPage()} disabled={!canNextPage}>
      {'>'}
    </Button>{' '}
    <Button className="super-stubby-input" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {'>>'}
    </Button>{' '}
    <span>
      &nbsp;&nbsp; Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
    </span>
    <span>
      | Go to page &nbsp;
      <input
        className="form-control super-stubby-input"
        type="number"
        defaultValue={pageIndex + 1}
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
        style={{ width: '100px' }}
      />
    </span>{' '}
    <select
      className="form-control stubby-input"
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value))
      }}
    >
      {[10, 20, 50, 100].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      ))}
    </select>
    <Button className="export-table" onClick={() => {csvLink.link.click()}}>
      Export <i className="fas fa-cloud-download-alt"></i>
    </Button>
    <CSVLink
      data={exportableData}
      ref={(r) => csvLink = r}
      filename="export.csv"
      className="hidden"
      target="_blank"
    />
  </div>);

  return (
    <>
      <table {...getTableProps()} className="table table-striped customer-table table-bordered">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                    {column.filter ? column.render(filters[column.filter]) : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr onClick={(id) => rowClick(page[i].original._id)} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if(cell.column.type === "phone_number") {
                      return (<td {...cell.getCellProps()}>
                            <PhoneInput
                              disabled={true}
                              value={cell.value}
                              onChange={() => {}}
                            />
                          </td>);
                    } else if(cell.column.type === "calendar") {
                      return (
                        <td {...cell.getCellProps()}>
                          <LanguageContext.Consumer>{language => (
                            moment(cell.value * 1000).calendar(null, language.datetimes)
                          )}</LanguageContext.Consumer>
                        </td>
                      );
                    } else if(cell.column.type === "date") {
                      return (
                        <td {...cell.getCellProps()}>
                          <LanguageContext.Consumer>{language => (
                            moment(cell.value, "YYYY-MM-DD").format(language.dates.sameElse)
                          )}</LanguageContext.Consumer>
                        </td>
                      );
                    } else if(cell.column.type === "switch") {
                      return (
                        <td {...cell.getCellProps()}>
                          <LanguageContext.Consumer>{language => (
                            (cell.value) ? <BarLoader size={10} /> : language.common.waiting_list
                          )}</LanguageContext.Consumer>
                        </td>
                      );
                    } else if(cell.column.type === "origin") {
                      return (
                        <td {...cell.getCellProps()}>
                          {renderOrigin(cell.value)}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className={`rt-${cell.column.type}-cell`}
                          {...cell.getCellProps()}>
                          {cell.render('Cell')}</td>
                      );
                    }
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
      <br />

      {(pageOptions.length > 1) ? pagination : ""}
    </>
  )
}

export default function ReactiveTable(props) {
  const columns = React.useMemo(
    () => props.columns,
    []
  );
  return (
    <Table columns={columns} data={props.data} rowClick={(id) => props.rowClick(id)} />
  );
}
