import React from 'react';
import moment from "moment";
import BarLoader from 'react-spinners/BarLoader';
import PhoneInput from 'react-phone-number-input';
import web from "assets/img/logo.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import 'react-phone-number-input/style.css';

const dateFormat = "YYYY-MM-DD";

const renderOrigin = (name) => {
  if ( ! name) return "";
  if(name === "web")
    return <img className="origin-logo" src={web} />;
  if(name === "facebook")
    return <img className="origin-logo" src={facebook} />;
  if(name === "instagram")
    return <img className="origin-logo" src={instagram} />;
}

function renderBoxedBooking(data, rowClick) {
  return (
    <div className={`box-row ${data.accepted ? "" : "unconfirmed"}`} onClick={() => rowClick(data._id)}>
      <div className="header-text">
        <i class="fas fa-user-friends"></i>{data.group_size}
        <i class="fas fa-clock"></i>{data.time}
        <i class="fas fa-calendar-alt"></i>{moment(data.date, dateFormat).format("MMM Do")}
      </div>
      <div className="box-body">
        {data.first_name} {data.last_name}
        <PhoneInput
          disabled={true}
          value={data.phone_number}
          onChange={() => {}}
        />
        {data.additional_info && <div className="additional-info">{data.additional_info}</div>}
      </div>
      <div className="box-footer">
        {data.accepted
          ? moment.unix(data.created).format("MMMM Do YYYY - HH:mm")
          : <div className="barloader-container"><BarLoader size={10} /></div>
        }
        {renderOrigin(data.origin)}
      </div>
    </div>
  );
}

function renderBoxedClient(columns, data, rowClick) {
  return (
    <div className="box-row" onClick={() => rowClick(data._id)}>
      <div className="box-body">
        {data.first_name} {data.last_name}
        <PhoneInput
          disabled={true}
          value={data.phone_number}
          onChange={() => {}}
        />
      </div>
      <div className="box-footer">
        <span className="soft-label">{columns[3].Header}</span>
        {moment.unix(data.recent_visit_unix).format("MMMM Do YYYY - HH:mm")}
      </div>
    </div>
  );
}

export default function MobileTable(props) {
  return (
    <div className="mobile-table">
      {props.type === "client"
        ? props.data.map(row => renderBoxedClient(props.columns, row, props.rowClick))
        : props.data.map(row => renderBoxedBooking(row, props.rowClick))
      }
    </div>
  );
}
