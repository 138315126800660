// Analytics

import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import LanguageContext from "LanguageContext";
import { Bar, Line } from 'react-chartjs-2';
import { CHART } from 'resources/constants';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
    };
  };

  async componentDidMount() {
    const accessString = localStorage.getItem('JWT');
    const response = await axios.get("getClientBookings", {
      headers: { Authorization: `JWT ${accessString}` },
    });
    this.setState({
      isLoading: false,
      covers_per_booking: this.coversPerBooking(response.data),
      bookings_per_weekday: this.bookingsPerWeekday(response.data),
    });
  }

  coversPerBooking(data) {
    data = data.map(booking => booking.group_size)
    var counts = {};

    for (var i = 0; i < data.length; i++) {
        var num = data[i];
        counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    data = {
        labels: Object.keys(counts).map(key => `${key} cover${key > 1 ? "s" : ""}`),
        datasets: [{
            data: Object.values(counts),
            backgroundColor: CHART.BG_COLORS,
            borderColor: CHART.BORDER_COLORS,
            borderWidth: 1
        }]
        };
    return data
  }

  bookingsPerWeekday(data) {
    data = data.map(booking => moment(booking.date, "YYYY-MM-DD").format("dddd"));

    var counts = {};

    for (var i = 0; i < data.length; i++) {
        var num = data[i];
        counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    data = {
        labels: Object.keys(counts),
        datasets: [{
            data: Object.values(counts),
            backgroundColor: CHART.BG_COLORS[3],
            borderColor: CHART.BORDER_COLORS[3],
            borderWidth: 1
        }]
        };
    return data
  }

  render() {
    if(this.state.isLoading) return <div className="loading"><BeatLoader size={50} /></div>;
    return (
      <LanguageContext.Consumer>{language => (
        <>
            <h2>{language.analytics.covers_per_booking}</h2>
            <Bar data={this.state.covers_per_booking} options={CHART.OPTIONS.BASIC} />
            <h2>{language.analytics.bookings_per_weekday}</h2>
            <Line data={this.state.bookings_per_weekday} options={CHART.OPTIONS.BASIC} />
        </>
      )}</LanguageContext.Consumer>
    );
  }
}

export default Analytics;
