import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Button } from "react-bootstrap";
import LanguageContext from "LanguageContext";

class Logout extends Component {
  constructor() {
    super();
    this.state = { loggedIn : true };
  }

  handleLogout = () => {
    localStorage.removeItem("JWT");
    this.setState({ loggedIn : false });
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <LanguageContext.Consumer>
          {language => (
            <Button
              id="logout-button"
              className="btn-long"
              variant="danger"
              onClick={() => this.handleLogout()}>
                {language.common.logout}
            </Button>
          )}
        </LanguageContext.Consumer>
      );
    } else {
      return (<Redirect to={"/home#login"} />);
    }
  }
}

export default Logout;
