import React from 'react';
import moment from "moment";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import LanguageContext from "LanguageContext";

export default function Sitting(props) {
  /* "handleSubmit" will validate inputs before invoking "onSubmit" */
  const { register, handleSubmit, watch, errors } = useForm();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isAutoConfirm, setIsAutoConfirm] = React.useState(props.details.auto);

  const onSubmit = async (data) => {
    data["auto"] = isAutoConfirm;
    setIsSubmitted(true);
    props.updateOpeningHours(props.type, props.dates, data, false);
  }

  const toggleAutoConfirm = () => {
    setIsAutoConfirm( ! isAutoConfirm);
  }

  const deleteSitting = id => {
    props.updateOpeningHours(props.type, props.dates, {id:id}, true);
  }

  const twentyFourHours = () => {
    let times = [];
    let i = "08:00";
    while(parseInt(i.replace(":", "")) < parseInt("23:45".replace(":", ""))) {
      times.push(i);
      i = moment(i, "HH:mm").add(15, 'minutes').format("HH:mm");
    }
    return times;
  }

  const fewHours = () => {
    let times = [];
    let i = "00:30";
    while(parseInt(i.replace(":", "")) < parseInt("4:00".replace(":", ""))) {
      times.push(i);
      i = moment(i, "HH:mm").add(15, 'minutes').format("HH:mm");
    }
    return times;
  }

  return (
    <LanguageContext.Consumer>{language => (
    <Form 
      className={(props.details.name || isSubmitted) ? "sitting" : "sitting unsaved"} 
      key={props.details.name} 
      onSubmit={handleSubmit(onSubmit)}
    >
    <Form.Group as={Row}>
      <Form.Control
        name="name"
        ref={register({ required: true })}
        placeholder={language.settings.sitting_placeholder}
        className="sitting-name"
        defaultValue={(props.details.name) ? props.details.name : ""}
      />
    </Form.Group>

    <Form.Group as={Row}>
      <Form.Control
        name="id"
        type="hidden"
        ref={register({ required: true })}
        placeholder="Sitting ID"
        className="sitting-id"
        defaultValue={(props.details.id) ? props.details.id : moment().unix()}
      />
    </Form.Group>

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.from}</Form.Label>
      <Col><Form.Control
        as="select"
        name="opens"
        ref={register({ required: true })}
        placeholder="Opens"
        className={(errors.name) ? "error" : ""}
        defaultValue={(props.details.opens) ? props.details.opens : ""}
      >
        {twentyFourHours().map(time => { return (
          <option key={`opens-${time}`} value={time}>{time}</option>
        )})}
      </Form.Control></Col>
    </Form.Group>

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.until}</Form.Label>
      <Col><Form.Control
        as="select"
        name="closes"
        ref={register({ required: true })}
        placeholder="Closes"
        className={(errors.name) ? "error" : ""}
        defaultValue={(props.details.closes) ? props.details.closes : ""}
        >
          {twentyFourHours().map(time => { return (
            <option key={`closes-${time}`} value={time}>{time}</option>
          )})}
        </Form.Control></Col>
    </Form.Group>

    <hr />

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.auto_confirm}</Form.Label>
      <Col>
        <BootstrapSwitchButton
          name="auto"
          checked={props.details.auto}
          onChange={() => toggleAutoConfirm()}
        />
      </Col>
    </Form.Group>

    {isAutoConfirm ? <>

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.capacity}</Form.Label>
      <Col><Form.Control
        type="number"
        name="capacity"
        ref={register({ required: true })}
        placeholder={language.settings.capacity_placeholder}
        className={(errors.name) ? "error" : ""}
        defaultValue={(props.details.capacity) ? props.details.capacity : ""}
      /></Col>
    </Form.Group>

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.max_group_size}</Form.Label>
      <Col><Form.Control
        type="number"
        name="max_group_size"
        ref={register({ required: true })}
        placeholder={language.settings.capacity_placeholder}
        className={(errors.name) ? "error" : ""}
        defaultValue={(props.details.max_group_size) ? props.details.max_group_size : ""}
      /></Col>
    </Form.Group>

    <Form.Group as={Row}>
      <Form.Label as={Col}>{language.settings.duration}</Form.Label>
      <Col><Form.Control
        as="select"
        name="duration"
        ref={register({ required: true })}
        placeholder="Booking Duration"
        className={(errors.name) ? "error" : ""}
        defaultValue={(props.details.duration) ? props.details.duration : ""}
      >
        {fewHours().map(time => { return (
          <option key={`duration-${time}`} value={time}>{time}</option>
        )})}
      </Form.Control></Col>
    </Form.Group>

    </> : "" }

    <div className="button-container">
      <Button type="submit">{(props.details.name || isSubmitted) ? language.settings.update : language.settings.save}</Button>
      <Button variant="danger" onClick={(id) => deleteSitting(props.details.id)}>
        {language.settings.delete}
      </Button>
    </div>
    </Form>
    )}</LanguageContext.Consumer>
  );
}
